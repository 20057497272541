import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import MateriasComponent from '../../../components/pagesComponents/materiasComponents/MateriasComponent';

const MateriasAgendaCoordenacao = () => {
  const itemsPagesList = itemsPagesWithExclusions('', ['agenda','Informes','Atividades','Chat','Fichas','Cardápio']);
  return (
      <>
        <MateriasComponent itemsPages={itemsPagesList} returnLink={"/agenda"} returnLinkName={"Agenda"}/>
      </>
    )
}

export default MateriasAgendaCoordenacao;
