import React from 'react';
import BoletinsAlunosComponent from '../../../../components/pagesComponents/BoletimComponents/BoletinsAlunos/BoletinsAlunosComponent';
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';

const BoletinsSecretariaAlunosPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', ['Atividades', 'Chat']);

    return (
        <BoletinsAlunosComponent itemsPages={itemsPagesList} />
    );
};

export default BoletinsSecretariaAlunosPage;
