import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import InterfaceStructure from '../../../components/InterfaceStructure';
import styles from './CalendarioProfessorPage.module.css';
import useTenantId from '../../../components/tenantId';
import moment from 'moment';
import apiAxios from '../../../api/apiAxios';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import CalendarioProfessorViewComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioView/CalendarioProfessorViewComponent';
import CalendarioComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioComponent';

const CalendarioProfessorPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Calendário', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio']);

  return (
    <div>
      <CalendarioComponent itemsPages={itemsPagesList} accessLevel="low" />
    </div>
  );
};

export default CalendarioProfessorPage;
