import React from 'react'
import BoletimComponent from '../../../components/pagesComponents/BoletimComponents/BoletimComponent'
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const BoletimAdminPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', []);
    return (
        <BoletimComponent PagesList={itemsPagesList} />
    )
}

export default BoletimAdminPage