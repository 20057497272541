import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import AgendaComponent from '../../../components/pagesComponents/AgendaComponents/AgendaComponent';

const AgendaCoordenacaoPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Agenda', ['agenda','Informes','Atividades','Chat','Fichas','Cardápio']);

    return (
        <AgendaComponent PagesList={itemsPagesList}/>
    );
};

export default AgendaCoordenacaoPage;
