import React, { useState } from "react";
import styles from "./BoletimInfantilComponent.module.css";
import apiAxios from "../../../../api/apiAxios";

const BoletimInfantilComponent = ({ boletimAluno, tenantId, boletimId, alunoId }) => {
    const [relatorios, setRelatorios] = useState(boletimAluno.relatorios);

    // Função para lidar com a atualização dos tipos nos períodos e salvar automaticamente
    const handleTipoChange = async (areaGeralIndex, especificaIndex, competenciaIndex, periodoIndex, newTipo) => {
        const updatedRelatorios = [...relatorios];
        updatedRelatorios[areaGeralIndex].areasEspecificas[especificaIndex].competencias[competenciaIndex].periodos[periodoIndex].tipo = newTipo;
        setRelatorios(updatedRelatorios);

        try {
            await apiAxios.put("/api/boletims/boletins/aluno", {
                tenantId,
                boletimId,
                alunoId,
                relatorios: updatedRelatorios,
            });
            console.log("Tipo atualizado com sucesso");
        } catch (error) {
            console.error("Erro ao atualizar o tipo:", error);
        }
    };

    // Calcula o número total de linhas para o rowSpan da área geral
    const calculateRowSpanForGeneralArea = (generalArea) => {
        return generalArea.areasEspecificas.reduce(
            (acc, especifica) => acc + especifica.competencias.length,
            0
        );
    };

    const uniquePeriods = relatorios[0]?.areasEspecificas[0]?.competencias[0]?.periodos.map(p => p.periodo) || [];

    return (
        <>
            <div className={styles.container}>
                <h3 className={styles.title}>Orientações</h3>
                <ul className={styles.guidelines}>
                    <li><strong>EP:</strong> Em Progresso - Indica que o aluno está em fase de desenvolvimento.</li>
                    <li><strong>S:</strong> Sim - Indica que a competência foi alcançada.</li>
                    <li><strong>N:</strong> Não - Indica que a competência não foi alcançada.</li>
                </ul>
            </div>
            <div className={styles.container}>
                <h3 className={styles.title}>Relatório de Desenvolvimento</h3>
                <table className={styles.boletimTabela}>
                    <thead>
                        <tr>
                            <th>Área Geral</th>
                            <th>Competência</th>
                            {uniquePeriods.map((period, idx) => (
                                <th key={`period-header-${idx}`}>{period}</th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {relatorios.map((generalArea, generalIdx) => (
                            generalArea.areasEspecificas.map((especifica, especificaIdx) =>
                                especifica.competencias.map((competencia, compIdx) => (
                                    <tr key={`${generalIdx}-${especificaIdx}-${compIdx}`}>
                                        {especificaIdx === 0 && compIdx === 0 && (
                                            <td rowSpan={calculateRowSpanForGeneralArea(generalArea)} className={styles.areaGeralCell}>
                                                {generalArea.areaGeral.nome || "Área Geral não definida"}
                                            </td>
                                        )}
                                        <td>{competencia.competencia || "Competência não definida"}</td>
                                        {competencia.periodos.map((periodo, periodoIdx) => (
                                            <td
                                                key={`${generalIdx}-${especificaIdx}-${compIdx}-${periodoIdx}`}
                                                data-label={uniquePeriods[periodoIdx]} // Adiciona o rótulo
                                            >
                                                <select
                                                    value={periodo.tipo || "EP"}
                                                    onChange={(e) =>
                                                        handleTipoChange(generalIdx, especificaIdx, compIdx, periodoIdx, e.target.value)
                                                    }
                                                >
                                                    <option value="S">Sim</option>
                                                    <option value="N">Não</option>
                                                    <option value="EP">Em Progresso</option>
                                                </select>
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            )
                        ))}
                    </tbody>

                </table>
            </div>
        </>
    );
};

export default BoletimInfantilComponent;
