import React, { useState, useEffect } from 'react';
import styles from './AgendaEditComponent.module.css';
import { useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';
import { RiDeleteBinLine, RiDeleteBin5Line } from "react-icons/ri";
import Message from '../tools/Message';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const AgendaEditComponent = ({ agendaId, serie, turno, seriesFilt, turnosFilt }) => {
    const [materia, setMateria] = useState([]);
    const tenantId = useTenantId();
    const navigate = useNavigate();
    const [initialMateria, setInitialMateria] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState(null);

    useEffect(() => {
        const fetchAgendaData = async () => {
            try {
                const response = await apiAxios.get(`/api/agenda/get/${agendaId}`);

                // Verifique se a resposta contém a chave `materia` e qual é a estrutura de resposta
                if (response.data && response.data.materia) {
                    setMateria(response.data.materia); // Direto, sem transformação para testar
                    setInitialMateria(JSON.parse(JSON.stringify(response.data.materia)));
                } else {
                    setMateria([]);
                }
            } catch (error) {
                console.error("Erro ao carregar dados da agenda:", error);
                setFeedbackMessage({ type: "error", message: "Erro ao carregar dados da agenda." });
            }
        };

        fetchAgendaData();
    }, [agendaId]);

    const handleMouseEnter = (index) => setHoveredIndex(index);
    const handleMouseLeave = () => setHoveredIndex(null);

    const handleMateriaChange = (index, field, value) => {
        const updatedMateria = [...materia];
        updatedMateria[index] = {
            ...updatedMateria[index],
            [field]: value
        };
        setMateria(updatedMateria);
    };

    const handleRemoveMateria = (index) => {
        const updatedMateria = [...materia];
        updatedMateria.splice(index, 1);
        setMateria(updatedMateria);
    };

    const handleUndo = async () => {
        try {
            console.log("Fetching materias padrão...");
            const response = await apiAxios.get(`/api/mat/materias?tenantId=${tenantId}&serie=${serie}`);
            const materiasPadrao = response.data?.[0]?.materia || []; // Acessando o array de matérias corretamente
            console.log("Materias padrão recebidas:", materiasPadrao);

            const restoredMateria = [...materia];

            // Verificar matérias padrão que não estão na lista atual
            materiasPadrao.forEach((materiaPadrao) => {
                if (!restoredMateria.find((mat) => mat.materia === materiaPadrao.materia)) {
                    console.log("Adicionando matéria faltante:", materiaPadrao);
                    restoredMateria.push(materiaPadrao);
                }
            });

            console.log("Lista de matérias após restaurar:", restoredMateria);
            setMateria(restoredMateria);
            setFeedbackMessage({ type: 'warning', message: 'Matérias faltantes foram restauradas!' });
        } catch (error) {
            console.error("Erro ao restaurar matérias padrão:", error);
            setFeedbackMessage({ type: 'error', message: 'Erro ao restaurar matérias padrão.' });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const removeEmptyFields = (obj) => {
            return Object.entries(obj)
                .filter(([_, v]) => v != null && v !== '')
                .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
        };

        const agendaAtualizada = removeEmptyFields({
            tenantId,
            serie,
            turno,
            materia: materia.map(item => removeEmptyFields({
                materia: item.materia,
                color: item.color,
                description: item.description,
                objetivoConhecimento: item.objetivoConhecimento,
                habilidades: item.habilidades,
                metodologia: item.metodologia,
                material: item.material
            })),
        });

        try {
            await apiAxios.patch(`/api/agenda/${agendaId}`, agendaAtualizada);
            setFeedbackMessage({ type: 'success', message: 'Agenda atualizada com sucesso!' });
            navigate('/agenda');
        } catch (error) {
            console.error('Erro ao atualizar agenda:', error);
            setFeedbackMessage({ type: 'error', message: 'Erro ao atualizar agenda.' });
        }
    };

    const renderMaterias = () => {
        return materia.length > 0 ? (
            materia.map((mat, index) => (
                <div key={index} className={styles.materiaItem}>
                    <div className={styles.materiaItemHeader}>
                        <h2 style={{ borderLeft: `4px solid ${mat.color}` }}>{mat.materia || 'Matéria Sem Nome'}</h2>
                        <button
                            onClick={() => handleRemoveMateria(index)}
                            className={styles.removeButton}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {hoveredIndex === index ? <RiDeleteBin5Line /> : <RiDeleteBinLine />}
                        </button>
                    </div>
                    <input
                        type="text"
                        placeholder="Objetivo de Conhecimento"
                        value={mat.objetivoConhecimento}
                        onChange={(e) => handleMateriaChange(index, 'objetivoConhecimento', e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Habilidades"
                        value={mat.habilidades}
                        onChange={(e) => handleMateriaChange(index, 'habilidades', e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Metodologia"
                        value={mat.metodologia}
                        onChange={(e) => handleMateriaChange(index, 'metodologia', e.target.value)}
                    />
                    <div>
                        <ReactQuill
                            theme="snow"
                            value={mat.material || ''}
                            onChange={(content) => {
                                if (mat.material !== content) {
                                    handleMateriaChange(index, 'material', content);
                                }
                            }}
                            placeholder="Insira o conteúdo do material aqui..."
                        />
                    </div>
                </div>
            ))
        ) : (
            <p>Este item não possui matérias para exibir.</p>
        );
    };


    return (
        <>
            {feedbackMessage && <Message type={feedbackMessage.type} message={feedbackMessage.message} />}
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.BasicInfoForm}>
                    <h2>Agenda ({serie} - {turno})</h2>
                    {renderMaterias()}
                    <div className={styles.controlButton}>
                        <button
                            type="submit"
                            className={styles.submitButton}
                            data-tooltip-id="salvar-tooltip"
                        >
                            Salvar Alterações
                        </button>
                        <button
                            type="button"
                            onClick={handleUndo}
                            data-tooltip-id="restaurar-tooltip"
                        >
                            Restaurar Matérias
                        </button>

                        {/* Tooltip para "Salvar Alterações" */}
                        <Tooltip id="salvar-tooltip" place="top" effect="solid">
                            Clique para salvar todas as alterações feitas.
                        </Tooltip>

                        {/* Tooltip para "Restaurar Matérias" */}
                        <Tooltip id="restaurar-tooltip" place="top" effect="solid">
                            Clique para restaurar as matérias padrão da série.
                        </Tooltip>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AgendaEditComponent;
