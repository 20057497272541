import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styles from './AgendaComponent.module.css';
import { IoIosAddCircleOutline } from 'react-icons/io';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import InterfaceStructure from '../../../components/InterfaceStructure';
import AgendaViewComponent from './AgendaView/AgendaViewComponent';
import AgendaEditComponent from './AgendaEditComponent';
import { LiaSchoolSolid } from "react-icons/lia";
import BasicPageStructure from '../../BasicPageStructure';
import AgendaCreateComponent from './AgendaCreateComponent';
import PopUpComponent from '../../popUp/PopUpComponent';
import { series, turno } from '../../../DataSeries';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../tenantId';

const removeDuplicates = (array) => [...new Set(array)];

const AgendaComponent = ({ PagesList, allowSeries = series, allowTurnos = turno, accessLevel }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [events, setEvents] = useState([]);
    const [hasEvents, setHasEvents] = useState(false);
    const [isCreateAgendaOpen, setIsCreateAgendaOpen] = useState(false);
    const [isViewAgendaOpen, setIsViewAgendaOpen] = useState(false);
    const [isEditAgendaOpen, setIsEditAgendaOpen] = useState(false);
    const [selectedSeries, setSelectedSeries] = useState(allowSeries[0] || '');
    const [selectedTurno, setSelectedTurno] = useState(allowTurnos[0] || '');
    const [selectedEventId, setSelectedEventId] = useState(null);
    const tenantId = useTenantId();
    const [filteredTurnos, setFilteredTurnos] = useState([]);

    const turnosAtuais = allowTurnos.length > 0 ? allowTurnos : turno; // Fallback para turnos importados

    console.log(turnosAtuais)


    function agruparSeriesETurnos(series, turnos) {
        const result = [];

        allowSeries.forEach((serie, index) => {
            const turno = allowTurnos[index];
            const existingEntry = result.find(entry => entry.serie === serie);

            if (existingEntry) {
                // Adiciona o turno à série existente, se ainda não estiver na lista
                if (!existingEntry.turnos.includes(turno)) {
                    existingEntry.turnos.push(turno);
                }
            } else {
                // Adiciona uma nova série com o turno
                result.push({ serie, turnos: [turno] });
            }
        });

        return result;
    }

    const estruturaAgrupada = agruparSeriesETurnos(allowSeries, allowTurnos);

    const navigate = useNavigate();

    const fetchEvents = async () => {
        try {
            const response = await apiAxios.get('/api/agenda/' + tenantId);
            const formattedEvents = response.data.map(event => ({
                ...event,
                date: new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')),
            }));
            setEvents(formattedEvents);
        } catch (error) {
            console.error('Erro ao buscar eventos:', error);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    useEffect(() => {
        if (!selectedSeries && allowSeries.length > 0) {
            setSelectedSeries(allowSeries[0]);
        }
        if (!selectedTurno && allowTurnos.length > 0) {
            setSelectedTurno(allowTurnos[0]);
        }
    }, [allowSeries, allowTurnos, selectedSeries, selectedTurno]);

    useEffect(() => {
        // Verifica se há turnos associados à série selecionada
        if (!allowTurnos || allowTurnos.length === 0) {
            // Se não houver turnos retornados, use os turnos importados
            setFilteredTurnos(turno);
        } else {
            // Se houver turnos retornados, associe-os à série
            const currentEntry = allowSeries.includes(selectedSeries)
                ? allowTurnos.filter((_, index) => allowSeries[index] === selectedSeries)
                : turno; // Use todos os turnos como fallback
    
            // Define os turnos filtrados com base nos dados disponíveis
            setFilteredTurnos(currentEntry.length > 0 ? currentEntry : turno);
        }
    
        // Reseta o turno selecionado se ele não for válido
        if (!turno.includes(selectedTurno)) {
            setSelectedTurno(turno[0] || ''); // Define o primeiro turno válido ou vazio
        }
    }, [selectedSeries, allowSeries, allowTurnos, selectedTurno, turno]);
    
    

    const handleSeriesChange = (event) => {
        const newSeries = event.target.value;
        setSelectedSeries(newSeries);
    
        // Sempre redefina os turnos para incluir todos os turnos disponíveis
        setFilteredTurnos(turno);
        setSelectedTurno(turno[0] || ''); // Redefine para o primeiro turno disponível
    };
    
    useEffect(() => {
        // Sempre liste todos os turnos importados para qualquer série
        setFilteredTurnos(turno);
    
        // Reseta o turno selecionado caso não seja válido
        if (!turno.includes(selectedTurno)) {
            setSelectedTurno(turno[0] || ''); // Define o primeiro turno disponível ou vazio
        }
    }, [selectedSeries, turno, selectedTurno]);
    


    const handleTurnoChange = (event) => {
        setSelectedTurno(event.target.value);
    };

    const filteredEvents = events.filter(event =>
        event.serie === selectedSeries && event.turno === selectedTurno
    );

    const handleDateClick = (date) => {
        const formattedDate = date.toISOString().split('T')[0];
        const eventExists = filteredEvents.some(event => {
            const eventDate = event.date.toISOString().split('T')[0];
            return eventDate === formattedDate;
        });

        if (eventExists) {
            setSelectedDate(date);
            setHasEvents(true);
            setIsViewAgendaOpen(true);
        } else {
            setSelectedDate(null);
            setHasEvents(false);
        }
    };

    const closeViewAgendaPopup = () => {
        setSelectedDate(null);
        setHasEvents(false);
        setIsViewAgendaOpen(false);
    };

    const openCreateAgendaPopup = () => {
        setIsCreateAgendaOpen(true);
        fetchEvents();
    };

    const closeCreateAgendaPopup = () => {
        setIsCreateAgendaOpen(false);
        fetchEvents();
    };

    const openEditPopup = (eventId) => {
        setSelectedEventId(eventId);
        setIsViewAgendaOpen(false);
        setIsEditAgendaOpen(true);
    };

    const closeEditPopup = () => {
        setIsEditAgendaOpen(false);
        setIsViewAgendaOpen(true);
        fetchEvents();
    };

    const highlightDates = ({ date, view }) => {
        if (view === 'month') {
            const dateString = date.toISOString().split('T')[0];
            const event = filteredEvents.find(event => {
                const eventDate = event.date;
                return eventDate && eventDate.toISOString().split('T')[0] === dateString;
            });

            if (event) {
                return (
                    <div
                        className={styles.highlight}
                        style={{ backgroundColor: event.agendastatus ? '#ffde59' : '#ec3237' }}
                    />
                );
            }
        }
        return null;
    };

    console.log(selectedTurno)

    const dashboardContent = (
        <div className={styles.calendarWrapper}>
            <div className={styles.filterWrapper}>
                <div>
                    <label>Série:</label>
                    <select value={selectedSeries} onChange={handleSeriesChange} required>
                        {removeDuplicates(allowSeries).map((serie) => (
                            <option key={serie} value={serie}>
                                {serie}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Turno:</label>
                    <select value={selectedTurno} onChange={handleTurnoChange} required>
                        {filteredTurnos.map((turno) => (
                            <option key={turno} value={turno}>
                                {turno}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <Calendar
                onClickDay={handleDateClick}
                prev2Label={null}
                next2Label={null}
                tileContent={highlightDates}
                className={styles.reactCalendar}
            />

            <div className={styles.categories}>
                <h2>Categorias</h2>
                <div className={styles.category}>
                    <span style={{ borderLeft: '3px solid #c72a2f' }}>Não Liberado para Alunos</span>
                </div>
                <div className={styles.category}>
                    <span style={{ borderLeft: '3px solid #FFDE59' }}>Liberado para Alunos</span>
                </div>
            </div>
        </div>
    );

    const buttons = [
        ...(accessLevel === 'professor'
            ? []
            : [
                {
                    label: 'Adicionar Matérias',
                    icon: LiaSchoolSolid,
                    onClick: () => navigate('/agenda/materias'),
                },
            ]),
        {
            label: 'Adicionar agenda',
            icon: IoIosAddCircleOutline,
            onClick: openCreateAgendaPopup,
        },
    ];


    return (
        <div>
            <InterfaceStructure itemsPages={PagesList} dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={false}
                    namePage="Agenda"
                    accessType='administrador'
                />
            } />

            <PopUpComponent
                title={`Criar Nova Agenda ${selectedSeries +" "+ selectedTurno}`}
                isOpen={isCreateAgendaOpen}
                onClose={closeCreateAgendaPopup}
            >
                <AgendaCreateComponent serie={selectedSeries} turno={selectedTurno} turnosFilt={allowTurnos} seriesFilt={allowSeries} events={events} onClose={closeCreateAgendaPopup} />
            </PopUpComponent>

            <PopUpComponent
                title={`Eventos para ${selectedSeries || 'Todas as Séries'} - ${selectedTurno || 'Todas as Séries'}`}
                isOpen={isViewAgendaOpen}
                onClose={closeViewAgendaPopup}
            >
                <AgendaViewComponent
                    date={selectedDate}
                    selectedSeries={selectedSeries}
                    selectedTurno={selectedTurno}
                    accessLevel={accessLevel}
                    onEdit={openEditPopup}
                />
            </PopUpComponent>

            <PopUpComponent
                title="Editar Agenda"
                isOpen={isEditAgendaOpen}
                onClose={closeEditPopup}
            >
                <AgendaEditComponent agendaId={selectedEventId} serie={selectedSeries} turnosFilt={allowTurnos} seriesFilt={allowSeries} turno={selectedTurno} />
            </PopUpComponent>
        </div>
    );
};

export default AgendaComponent;
