import React, { useEffect, useState } from 'react';
import styles from './RequestsComponent.module.css';
import InterfaceStructure from '../../../InterfaceStructure';
import { MdCalendarToday, MdKeyboardArrowLeft, MdOutlineClass, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../../../components/FormatNumber';
import { deleteRequestById, getAllRequests } from '../../../../actions/RequestActions';
import { FcApprove, FcDisapprove } from "react-icons/fc";
import { approveRequest } from '../../../../actions/UserActions';
import { IoMdTime } from "react-icons/io";

const RequestsComponent = ({ itemsPages }) => {
    const itemsPagesList = itemsPages;

    const [data, setData] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const IdUserLogged = tokenPayload?._id;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requests = await getAllRequests(tokenPayload.tenantId);
                const mappedRequests = requests
                    .filter(request => request._id !== IdUserLogged) // Filtra o usuário logado
                    .filter(request => !request.isAdmin && !request.isAdminMaster) // Filtra admin e adminmaster
                    .reverse()
                    .map(request => ({
                        id: request._id,
                        Number: formatPhoneNumber(request.phoneNumber),
                        user: request.firstName,
                        usersecond: request.lastName,
                        email: request.email,
                        admin: request.isAdmin,
                        coordenacao: request.isCoordenaçao,
                        nutricionista: request.isNutricionista,
                        Professor: request.isProfessor,
                        Aluno: request.isAluno,
                        Responsavel: request.isResponsavel,
                        emailVerified: request.emailVerified
                    }));
                setData(mappedRequests);
            } catch (error) {
                console.error("Erro ao obter solicitações:", error);
            }
        };
        fetchData();
    }, [tokenPayload.tenantId]);

    const handleApproveRequest = async (requestId) => {
        try {
            await approveRequest(requestId);
            setData(prevData => prevData.filter(request => request.id !== requestId));
        } catch (error) {
            console.error("Erro ao aprovar solicitação:", error);
        }
    };

    const handleDeleteRequest = async (requestId) => {
        try {
            await deleteRequestById(requestId);
            setData(prevData => prevData.filter(request => request.id !== requestId));
        } catch (error) {
            console.error("Erro ao excluir solicitação:", error);
        }
    };

    const columns = [
        {
            field: 'action',
            headerName: 'Ação',
            width: 400,
            renderCell: (params) => (
                <div className={styles.actionContainer}>
                    {/* Botão de Aprovação (só aparece se emailVerified for true) */}
                    {params.row.emailVerified ? (
                        <FcApprove
                            className={styles.Aprove}
                            onClick={() => handleApproveRequest(params.row.id)}
                        />
                    ) : (
                        <div className={styles.timeVerify}>
                            <IoMdTime style={{ fontSize: "20px" }} />
                            <span> Esperando Verificar Email.</span>
                        </div>
                    )}
                    {/* Botão de Reprovação (sempre visível) */}
                    <FcDisapprove
                        className={styles.Reprove}
                        onClick={() => handleDeleteRequest(params.row.id)}
                    />
                </div>
            ),
        },
        { field: 'user', headerName: 'Nome', width: 200 },
        { field: 'usersecond', headerName: 'Sobrenome', width: 200 },
        { field: 'email', headerName: 'Email', width: 230 },
        { field: 'Number', headerName: 'Número', width: 180 },
        { field: 'coordenacao', headerName: 'Coordenação', width: 180 },
        { field: 'nutricionista', headerName: 'Nutricionista', width: 180 },
        { field: 'Professor', headerName: 'Professor', width: 180 },
        { field: 'Responsavel', headerName: 'Responsável', width: 180 },
        { field: 'Aluno', headerName: 'Aluno', width: 180 },
    ];
    
    

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/'><MdKeyboardArrowLeft />Painel</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Solicitações de cadastro</h1>
                        <p>Administrador</p>
                    </div>
                    <div className={styles.end}>
                        ...
                    </div>
                </div>
                <div className={styles.userList}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={8}
                        disableSelectionOnClick
                        checkboxSelection
                    />
                </div>
            </div>
        </div>
    );

    return (
        <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
    );
}

export default RequestsComponent