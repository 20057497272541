import React, { useState, useEffect, useRef } from 'react';
import styles from './AgendaCreateComponent.module.css';
import { useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { RiDeleteBinLine, RiDeleteBin5Line } from "react-icons/ri";
import Message from '../tools/Message';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../../../pages/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const AgendaCreateComponent = ({ serie, turno, turnosFilt, seriesFilt, events, onClose }) => {
    const [materia, setMateria] = useState([]);
    const [startDate, setStartDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [selectedImage, setSelectedImage] = useState(null);
    const [imgUrl, setImgUrl] = useState('');
    const tenantId = useTenantId();
    const navigate = useNavigate();
    const [materiasCarregadas, setMateriasCarregadas] = useState(true);
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [initialMateria, setInitialMateria] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const [isCreating, setIsCreating] = useState(false);


    const storage = getStorage(app);

    useEffect(() => {
        if (feedbackMessage) {
            const timer = setTimeout(() => setFeedbackMessage(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [feedbackMessage]);

    useEffect(() => {
        const formattedDate = moment(startDate).format("DD/MM/YYYY 00:00:00");
        const hasConflict = events.some(event => {
            const eventDate = moment(event.date).format("YYYY-MM-DD");
            return (
                eventDate === formattedDate &&
                event.serie === serie &&
                event.turno === turno
            );
        });


        if (hasConflict) {
            setFeedbackMessage({
                type: 'warning',
                message: 'Já existe uma agenda para essa série, turno e data. Altere a série, turno ou data para continuar.'
            });
            setIsSubmitDisabled(true);
        } else {
            setFeedbackMessage(null);
            setIsSubmitDisabled(false);
        }
    }, [serie, turno, startDate, events]);

    useEffect(() => {
        const fetchMaterias = async () => {
            if (serie) {
                try {
                    const response = await apiAxios.get(`/api/mat/materias?serie=${serie}&tenantId=${tenantId}`);
                    const materiasDaSerie = response.data.filter(mat => mat.serie === serie);

                    if (materiasDaSerie.length === 0) {
                        setMateriasCarregadas(false);
                        setMateria([]);
                    } else {
                        const materiasFormatadas = materiasDaSerie.map(mat => ({
                            ...mat,
                            materia: mat.materia.map(item => ({
                                ...item,
                                objetivoConhecimento: '',
                                habilidades: '',
                                metodologia: '',
                                material: '',
                            }))
                        }));

                        setMateria(materiasFormatadas);
                        setInitialMateria(JSON.parse(JSON.stringify(materiasFormatadas)));
                        setMateriasCarregadas(true);
                    }
                } catch (error) {
                    console.error('Erro ao buscar matérias:', error);
                    setFeedbackMessage({ type: 'error', message: 'Erro ao buscar matérias.' });
                }
            }
        };

        fetchMaterias();
    }, [serie, tenantId]);

    useEffect(() => {
        const formattedDate = moment(startDate).format("YYYY-MM-DD"); // Formatar apenas para YYYY-MM-DD
        const hasConflict = events.some(event => {
            const eventDate = moment(event.date).format("YYYY-MM-DD");
            return (
                eventDate === formattedDate &&
                event.serie === serie &&
                event.turno === turno
            );
        });


        if (hasConflict) {
            setFeedbackMessage({
                type: 'warning',
                message: 'Já existe uma agenda para essa série, turno e data. Altere a série, turno ou data para continuar.'
            });
            setIsSubmitDisabled(true);
        } else {
            setFeedbackMessage(null);
            setIsSubmitDisabled(false);
        }
    }, [serie, turno, startDate, events]);

    const handleMouseEnter = (index, matIndex) => setHoveredIndex({ index, matIndex });
    const handleMouseLeave = () => setHoveredIndex(null);

    const handleMateriaChange = (index, matIndex, field, value) => {
        setMateria(prevState => {
            const updatedMateria = [...prevState];
            updatedMateria[index].materia[matIndex] = {
                ...updatedMateria[index].materia[matIndex],
                [field]: value
            };
            return updatedMateria;
        });
    };

    const handleRemoveMateria = (index, matIndex) => {
        const updatedMateria = [...materia];
        updatedMateria[index].materia.splice(matIndex, 1);
        if (updatedMateria[index].materia.length === 0) {
            updatedMateria.splice(index, 1);
        }
        setMateria(updatedMateria);
    };

    const handleUndo = () => setMateria(JSON.parse(JSON.stringify(initialMateria)));

    const handleImageUpload = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleImageDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        setSelectedImage(e.dataTransfer.files[0]);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const uploadImageToFirebase = async () => {
        if (!selectedImage) return '';

        const imageRef = ref(storage, `images/${selectedImage.name}`);
        await uploadBytes(imageRef, selectedImage);
        const url = await getDownloadURL(imageRef);
        setImgUrl(url);
        return url;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitDisabled || isCreating) return;

        setIsCreating(true); // Desativa o botão de envio
        const formattedDate = moment(startDate).format("DD/MM/YYYY HH:mm:ss");

        const materiaSimplificada = materia.flatMap(item => (
            item.materia.map(mat => ({
                ...item,
                materia: mat.materia,
                color: mat.color,
                description: mat.description,
                objetivoConhecimento: mat.objetivoConhecimento || '',
                habilidades: mat.habilidades || '',
                metodologia: mat.metodologia || '',
                material: mat.material || '',
            }))
        ));

        const imgUrl = await uploadImageToFirebase();

        const novaAgenda = {
            tenantId,
            date: formattedDate,
            serie, // Use diretamente o parâmetro
            turno, // Use diretamente o parâmetro
            materia: materiaSimplificada,
            imgUrl: imgUrl || '',
            agendastatus: false,
        };


        try {
            await apiAxios.post('/api/agenda/', novaAgenda);
            setFeedbackMessage({ type: 'success', message: 'Agenda criada com sucesso!' });

            // Exibe a mensagem de sucesso e fecha o modal após 5 segundos
            setTimeout(() => {
                setFeedbackMessage(null); // Remove a mensagem
                setIsCreating(false); // Reativa o botão de envio
                if (onClose) onClose(); // Fecha o modal se a prop onClose for passada
            }, 5000);
        } catch (error) {
            console.error('Erro ao criar agenda:', error);
            setFeedbackMessage({ type: 'error', message: 'Erro ao criar agenda.' });
            setIsCreating(false); // Reativa o botão de envio
        }
    };


    const renderMaterias = () => {
        if (!materiasCarregadas) {
            return <p>Nenhuma matéria encontrada para a série selecionada.</p>;
        }

        return materia.map((item, index) => (
            <div key={index} className={styles.materiaBlock}>
                {item.materia.map((mat, matIndex) => (
                    <div key={`${index}-${matIndex}`} className={styles.materiaItem}>
                        <div className={styles.materiaItemHeader}>
                            <h2 style={{ borderLeft: `4px solid ${mat.color}` }}>{mat.materia}</h2>
                            {/* Botão para remover matéria */}
                            <button
                                type="button"
                                onClick={() => handleRemoveMateria(index, matIndex)}
                                className={styles.removeButton}
                            >
                                <RiDeleteBinLine />
                            </button>
                        </div>
                        <input
                            type="text"
                            placeholder="Objetivo de Conhecimento"
                            value={mat.objetivoConhecimento}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'objetivoConhecimento', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Habilidades"
                            value={mat.habilidades}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'habilidades', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Metodologia"
                            value={mat.metodologia}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'metodologia', e.target.value)}
                        />
                        <ReactQuill
                            theme="snow"
                            value={mat.material}
                            onChange={(content) => handleMateriaChange(index, matIndex, 'material', content)}
                            placeholder="Insira o conteúdo do material aqui..."
                        />
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <>
            {feedbackMessage && <Message type={feedbackMessage.type} message={feedbackMessage.message} />}
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.BasicInfoForm}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(new Date(date.setHours(0, 0, 0, 0)))}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Selecione a data"
                        className={styles.datePicker}
                        required
                    />
                    {isSubmitDisabled && (<p style={{color:"red"}}>*Selecione uma data válida</p>)}

                    {!isSubmitDisabled && (
                        <>
                            <p>Insira uma imagem:</p>
                            <div
                                className={`${styles.imagedroparea} ${isDragging ? styles.dragActive : ''}`}
                                onDrop={handleImageDrop}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onClick={() => fileInputRef.current.click()}
                            >
                                Solte a imagem aqui ou clique para selecionar
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                />
                            </div>

                            {selectedImage && (
                                <div className={styles.previewContainer}>
                                    <img src={URL.createObjectURL(selectedImage)} alt="Pré-visualização" className={styles.previewImage} />
                                    <button onClick={() => setSelectedImage(null)} className={styles.removeButton}>X</button>
                                </div>
                            )}


                            {renderMaterias()}
                            <div className={styles.controlButton}>
                                <button
                                    type="submit"
                                    className={styles.submitButton}
                                    disabled={isCreating} // Botão desativado enquanto está criando
                                >
                                    {isCreating ? 'Criando...' : 'Criar Agenda'}
                                </button>
                                <button type="button" onClick={handleUndo}>
                                    Desfazer
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </>
    );
};

export default AgendaCreateComponent;
