import React from 'react';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';
import RequestsComponent from '../../components/pagesComponents/UsersComponents/requests/RequestsComponent';

const RequestsSecretaria = () => {
  const itemsPagesList =  itemsPagesWithExclusions('Painel', ['Atividades', 'Chat']);

  return (
    <RequestsComponent itemsPages={itemsPagesList}/>
  );
};

export default RequestsSecretaria;
