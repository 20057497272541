import React, { useEffect, useState } from 'react'
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';
import { useSelector } from 'react-redux';
import apiAxios from '../../../../api/apiAxios';
import BoletinsAlunosComponent from '../../../../components/pagesComponents/BoletimComponents/BoletinsAlunos/BoletinsAlunosComponent';
import Loading from '../../../../components/Loading/Loading';

const BoletinsAlunosProfessorPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio']);
    const [user, setUser] = useState();
    const [allowedSeries, setAllowedSeries] = useState([]); // Estado para séries permitidas
    const [allowedTurnos, setAllowedTurnos] = useState([]); // Estado para turnos permitidos
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
                const userData = response.data.user;
                setUser(userData);

                // Verificar se o usuário é professor e possui séries e turnos associados
                if (userData.isProfessor && userData.professorInfo?.length > 0) {
                    const series = userData.professorInfo.map(info => info.serie); // Extrair as séries
                    const turnos = userData.professorInfo.map(info => info.turno); // Extrair os turnos
                    setAllowedSeries(series); // Definir as séries permitidas
                    setAllowedTurnos(turnos); // Definir os turnos permitidos
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (tokenPayload && tokenPayload._id) {
            fetchUser();
        }
    }, [tokenPayload]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <BoletinsAlunosComponent itemsPages={itemsPagesList} series={allowedSeries} turno={allowedTurnos} />
    )
}

export default BoletinsAlunosProfessorPage