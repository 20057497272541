import React, { useState, useEffect } from 'react';
import ReactCalendar from 'react-calendar';
import './CustomReactCalendar.css'; // Seu CSS customizado
import 'react-calendar/dist/Calendar.css';
import styles from './FrequenciaComponent.module.css'; // Importa o CSS Module
import apiAxios from '../../../api/apiAxios';
import InterfaceStructure from '../../InterfaceStructure';
import { series } from '../../../DataSeries';
import BasicPageStructure from '../../BasicPageStructure';
import useTenantId from '../../tenantId';
import ReactQuill from 'react-quill'; // Importa o editor
import 'react-quill/dist/quill.snow.css'; // Importa os estilos do Quill
import sanitizeHtml from 'sanitize-html';

const FrequenciaComponent = ({ itemsPages, accessName, accessLevel, professorAccess }) => {
    const tenantId = useTenantId();
    const allowedSeries = professorAccess?.series || series;

    // Remover duplicatas de turnos
    const allowedTurnos = Array.from(
        new Set(professorAccess?.turnos || ['Manhã', 'Tarde', 'Noite'])
    );

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attendanceByDate, setAttendanceByDate] = useState([]);
    const [selectedSerie, setSelectedSerie] = useState(allowedSeries[0] || '');
    const [selectedTurno, setSelectedTurno] = useState(allowedTurnos[0] || '');
    const [lesson, setLesson] = useState('');
    const [savingLesson, setSavingLesson] = useState(false);
    const [lessonId, setLessonId] = useState(null);

    const [updatingStudentId, setUpdatingStudentId] = useState(null);

    const loadLesson = async (date) => {
        try {
            const dateStr = date.toLocaleDateString('en-CA');
            const response = await apiAxios.get(`/api/attendance/${tenantId}/lesson`, {
                params: { date: dateStr, serie: selectedSerie, turno: selectedTurno },
            });

            console.log(response)

            setLesson(response.data.lesson || '');
            setLessonId(response.data.id || null); // Armazena o ID do registro existente
        } catch (error) {
            console.error('Erro ao carregar a descrição da aula:', error);
            setLesson('');
            setLessonId(null);
        }
    };

    const saveLesson = async () => {
        setSavingLesson(true);
        try {
            const dateStr = selectedDate.toLocaleDateString('en-CA');
            const sanitizedLesson = sanitizeHtml(lesson, {
                allowedTags: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br'],
                allowedAttributes: { a: ['href', 'target'] },
            });

            if (lessonId) {
                // Atualiza o registro existente
                await apiAxios.put(`/api/attendance/${tenantId}/lesson/${lessonId}`, {
                    date: dateStr,
                    lesson: sanitizedLesson,
                    serie: selectedSerie,
                    turno: selectedTurno,
                });
                alert('Descrição da aula atualizada com sucesso!');
            } else {
                // Cria um novo registro
                const response = await apiAxios.post(`/api/attendance/${tenantId}/lesson`, {
                    date: dateStr,
                    lesson: sanitizedLesson,
                    serie: selectedSerie,
                    turno: selectedTurno,
                });
                setLessonId(response.data.lessonRecord._id); // Atualiza o `lessonId` com o novo registro
                alert('Descrição da aula salva com sucesso!');
            }
        } catch (error) {
            console.error('Erro ao salvar a descrição da aula:', error);
            alert('Erro ao salvar a descrição da aula.');
        } finally {
            setSavingLesson(false);
        }
    };


    useEffect(() => {
        loadStudents(selectedDate);
        if (selectedDate && selectedSerie && selectedTurno) {
            loadLesson(selectedDate);
        }
    }, [selectedDate, selectedSerie, selectedTurno, tenantId]);

    useEffect(() => {
        if (!selectedSerie) {
            setSelectedSerie(allowedSeries[0] || '');
        }
        if (!selectedTurno) {
            setSelectedTurno(allowedTurnos[0] || '');
        }
    }, [allowedSeries, allowedTurnos]);

    const loadStudents = async (date) => {
        setLoading(true);
        try {
            const dateStr = date.toLocaleDateString('en-CA');
            const response = await apiAxios.get(`/api/attendance/list/${tenantId}/${dateStr}`, {
                params: { serie: selectedSerie, turno: selectedTurno },
            });
            setStudents(response.data);
        } catch (error) {
            console.error('Erro ao carregar a lista de alunos:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const loadAttendanceByDate = async () => {
            try {
                const response = await apiAxios.get(`/api/attendance/${tenantId}/byDate`, {
                    params: { serie: selectedSerie, turno: selectedTurno },
                });
                setAttendanceByDate(response.data);
            } catch (error) {
                console.error('Erro ao carregar frequência por data:', error);
            }
        };

        if (selectedSerie && selectedTurno) {
            loadAttendanceByDate();
        }
    }, [selectedSerie, selectedTurno, tenantId]);

    useEffect(() => {
        loadStudents(selectedDate);
    }, [selectedDate, selectedSerie, selectedTurno, tenantId]);

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dateStr = date.toLocaleDateString('en-CA');

            const attendanceForDate = attendanceByDate.find(
                (item) => item.date === dateStr
            );

            if (attendanceForDate) {
                const { statuses } = attendanceForDate;

                if (statuses.includes('Absent')) {
                    return styles.absentDay; // Vermelho
                }
                if (statuses.includes('Excused')) {
                    return styles.excusedDay; // Azul
                }
                if (statuses.every((status) => status === 'Present')) {
                    return styles.presentDay; // Verde
                }
            } else {
                // console.log(`Nenhum dado encontrado para ${dateStr}.`);
            }
        }
        return null; // Sem estilo aplicado
    };

    const markAttendance = async (studentId, status) => {
        setUpdatingStudentId(studentId);
        try {
            const dateStr = selectedDate.toLocaleDateString('en-CA');
            const student = students.find((student) => student._id === studentId);

            const newStatus = student?.status === status ? null : status;

            await apiAxios.post(`/api/attendance/${tenantId}/mark`, {
                userId: studentId,
                date: dateStr,
                status: newStatus,
            });

            setStudents((prevStudents) =>
                prevStudents.map((student) =>
                    student._id === studentId ? { ...student, status: newStatus } : student
                )
            );
        } catch (error) {
            console.error('Erro ao marcar/desmarcar frequência:', error);
        } finally {
            setUpdatingStudentId(null);
        }
    };

    const filteredStudents = students.filter((student) => {
        const matchesSerie = selectedSerie ? student?.serie === selectedSerie : true;
        const matchesTurno = selectedTurno ? student?.turno === selectedTurno : true;
        return matchesSerie && matchesTurno;
    });

    const handleSerieChange = (e) => {
        setSelectedSerie(e.target.value);
    };

    const handleTurnoChange = (e) => {
        setSelectedTurno(e.target.value);
    };

    const dashboardContent = (
        <div className={styles.frequenciaAll}>
            <div className={styles.all}>
                <div className={styles.filters}>
                    <div>
                        <label htmlFor="serieSelect">Série:</label>
                        <select id="serieSelect" value={selectedSerie} onChange={handleSerieChange}>
                            {allowedSeries.map((serie) => (
                                <option key={serie} value={serie}>{serie}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="turnoSelect">Turno:</label>
                        <select id="turnoSelect" value={selectedTurno} onChange={handleTurnoChange}>
                            {allowedTurnos.map((turno) => (
                                <option key={turno} value={turno}>{turno}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className={styles.left} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={styles.calendar}>
                        <ReactCalendar
                            onChange={setSelectedDate}
                            value={selectedDate}
                            locale="pt-BR"
                            tileClassName={tileClassName}
                            prev2Label={null}
                            next2Label={null}
                        />
                    </div>
                    <div className={styles.legend}>
                        <h4>Legenda:</h4>
                        <ul>
                            <li><span className={`${styles.legendColor} ${styles.legendGreen}`}></span> Todos Presentes</li>
                            <li><span className={`${styles.legendColor} ${styles.legendRed}`}></span> Algum Ausente</li>
                            <li><span className={`${styles.legendColor} ${styles.legendBlue}`}></span> Algum Justificado</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.right}>
                    <h2>Frequência para o dia {selectedDate.toLocaleDateString('pt-BR')}</h2>

                    <div>
                        {loading ? (
                            <p>Carregando alunos...</p>
                        ) : filteredStudents.length === 0 ? (
                            <p>Nenhum aluno encontrado para esta data.</p>
                        ) : (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Matrícula</th>
                                        <th>Série</th>
                                        <th>Turno</th>
                                        <th>Presente</th>
                                        <th>Ausente</th>
                                        <th>Justificado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents.map((student) => (
                                        <tr key={student._id}>
                                            <td>{student.firstName} {student.lastName}</td>
                                            <td>{student.matricula}</td>
                                            <td>{student?.serie || 'N/A'}</td>
                                            <td>{student?.turno || 'N/A'}</td>
                                            <td>
                                                <button
                                                    onClick={() => markAttendance(student._id, 'Present')}
                                                    className={`${styles.statusButton} ${student.status === 'Present' ? `${styles.active} ${styles.present}` : ''}`}
                                                    disabled={updatingStudentId === student._id || accessLevel === 'low'}
                                                ></button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => markAttendance(student._id, 'Absent')}
                                                    className={`${styles.statusButton} ${student.status === 'Absent' ? `${styles.active} ${styles.absent}` : ''}`}
                                                    disabled={updatingStudentId === student._id || accessLevel === 'low'}
                                                ></button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => markAttendance(student._id, 'Excused')}
                                                    className={`${styles.statusButton} ${student.status === 'Excused' ? `${styles.active} ${styles.excused}` : ''}`}
                                                    disabled={updatingStudentId === student._id || accessLevel === 'low'}
                                                ></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>

                <div className={styles.lessonField}>
                    <h2>Registro de aula:</h2>
                    <div className={styles.react_quill_input}>
                        <ReactQuill
                            theme="snow"
                            value={lesson}
                            onChange={setLesson}
                            placeholder="Escreva aqui o que ocorreu na aula..."
                        />
                    </div>
                    <button onClick={saveLesson} disabled={savingLesson}>
                        {savingLesson ? 'Salvando...' : 'Salvar Registro'}
                    </button>
                </div>
            </div>
        </div>
    );

    const buttons = [];

    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={false}
                    namePage="Frequência"
                    accessType={accessName}
                />
            }
        />
    );
};

export default FrequenciaComponent;
