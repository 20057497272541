import React, { useEffect, useState } from 'react';
import styles from './PlanosComponent.module.css';
import InterfaceStructure from '../../InterfaceStructure';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { LiaSchoolSolid } from 'react-icons/lia';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../tenantId';
import { series, turno as turnos } from '../../../DataSeries'; // Renomeado aqui
import PopUpComponent from '../../popUp/PopUpComponent';
import PlanosComponentCreate from './PlanosComponentCreate';
import PlanosComponentEdit from './PlanosComponentEdit';
import img from '../../../assets/notFound/OIP.jpg';
import BasicPageStructure from '../../BasicPageStructure';

const PlanosComponent = ({ itemsPages, accessLevel, seriesAccess, turnosAccess }) => {
    const allowedSeries = seriesAccess;
    const allowedTurnos = turnosAccess;
    const itemsPagesList = itemsPages;
    const navigate = useNavigate();
    const tenantId = useTenantId();

    const [planos, setPlanos] = useState([]);
    const [serie, setSerie] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [turno, setTurno] = useState(turnos[0] || '');
    const [filteredPlanos, setFilteredPlanos] = useState([]);
    const [anosDisponiveis, setAnosDisponiveis] = useState([new Date().getFullYear()]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [planToDelete, setPlanToDelete] = useState(null);

    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const [materia, setMateria] = useState('');
    const [materiasDisponiveis, setMateriasDisponiveis] = useState([]);
    const [mes, setMes] = useState('');
    const [mesesDisponiveis, setMesesDisponiveis] = useState([]);

    const [availableTurnos, setAvailableTurnos] = useState([]);

    function agruparSeriesETurnos(series, turnos) {
        const result = [];

        allowedSeries.forEach((serie, index) => {
            const turno = allowedTurnos[index];
            const existingEntry = result.find(entry => entry.serie === serie);

            if (existingEntry) {
                // Adiciona o turno à série existente, se ainda não estiver na lista
                if (!existingEntry.turnos.includes(turno)) {
                    existingEntry.turnos.push(turno);
                }
            } else {
                // Adiciona uma nova série com o turno
                result.push({ serie, turnos: [turno] });
            }
        });

        return result;
    }

    const estruturaAgrupada = agruparSeriesETurnos(allowedSeries);
    console.log("OO", estruturaAgrupada)

    // Função para buscar planos
    const fetchPlanos = async () => {
        try {
            const response = await apiAxios.get(`/api/planos/planos?tenantId=${tenantId}`);
            setPlanos(response.data);

            const anos = [...new Set(response.data.map(plano => parseInt(plano.ano)))];
            if (anos.length > 0) {
                setAnosDisponiveis(anos);
                if (!anos.includes(new Date().getFullYear())) {
                    setAno(anos[0]);
                }
            }
        } catch (error) {
            console.error('Erro ao buscar planos:', error);
        }
    };

    // Atualize os planos automaticamente a cada intervalo
    useEffect(() => {
        if (tenantId) {
            fetchPlanos(); // Busca inicial dos planos
            const interval = setInterval(fetchPlanos, 5000); // Atualiza a cada 5 segundos
            return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
        }
    }, [tenantId]);

    useEffect(() => {
        const materias = new Set();
        const meses = new Set();
        planos.forEach(plano =>
            plano.planejamento.forEach(planejamento => {
                meses.add(planejamento.mes);
                planejamento.plano.forEach(item => materias.add(item.materia));
            })
        );
        setMateriasDisponiveis([...materias]);
        setMesesDisponiveis([...meses]);
    }, [planos]);

    // Filtrar séries com base no acesso
    const filteredSeries = allowedSeries.length === 0 ? series : series.filter(s => allowedSeries.includes(s));

    // Funções para manipulação das seleções
    const handleAnoChange = (e) => setAno(e.target.value);
    const handleMesChange = (e) => setMes(e.target.value);
    const handleMateriaChange = (e) => setMateria(e.target.value);

    useEffect(() => {
        const filtered = planos.filter(plano =>
            (!serie || plano.serie === serie) &&
            (!ano || plano.ano === String(ano)) &&
            (!turno || plano.turno === turno)
        ).map(plano => {
            let filteredPlanejamento = plano.planejamento;
            if (mes) {
                filteredPlanejamento = filteredPlanejamento.filter(planejamento => planejamento.mes === mes);
            }
            filteredPlanejamento = filteredPlanejamento.map(planejamento => {
                let filteredPlanoItems = planejamento.plano;
                if (materia) {
                    filteredPlanoItems = filteredPlanoItems.filter(item => item.materia === materia);
                }
                return { ...planejamento, plano: filteredPlanoItems };
            }).filter(planejamento => planejamento.plano.length > 0);
            return { ...plano, planejamento: filteredPlanejamento };
        }).filter(plano => plano.planejamento.length > 0);

        setFilteredPlanos(filtered);
    }, [planos, serie, ano, turno, mes, materia]);


    const handleCreatePlan = (newPlan) => {
        fetchPlanos();
        setIsCreatePopupOpen(false);
    };

    const handleUpdatePlan = (updatedPlan) => {
        fetchPlanos();
        setIsEditPopupOpen(false);
    };

    const handleDeleteClick = (id) => {
        setPlanToDelete(id);
        setShowDeletePopup(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await apiAxios.delete(`/api/planos/planos/${planToDelete}?tenantId=${tenantId}`);
            fetchPlanos();
            setShowDeletePopup(false);
            setPlanToDelete(null);
        } catch (error) {
            console.error('Erro ao deletar plano:', error);
        }
    };

    const openCreatePopup = () => setIsCreatePopupOpen(true);
    const closeCreatePopup = () => setIsCreatePopupOpen(false);

    const openEditPopup = (plan) => {
        setSelectedPlan(plan);
        setIsEditPopupOpen(true);
    };
    const closeEditPopup = () => {
        setSelectedPlan(null);
        setIsEditPopupOpen(false);
    };

    const handleDeleteCancel = () => {
        setShowDeletePopup(false);
        setPlanToDelete(null);
    };

    useEffect(() => {
        if (!serie && filteredSeries.length > 0) {
            setSerie(filteredSeries[0]); // Define a primeira série disponível
        }
    
        if (!availableTurnos.length) {
            setAvailableTurnos(turnos); // Use todos os turnos como fallback
        }
    
        if (!turno && availableTurnos.length > 0) {
            setTurno(availableTurnos[0]); // Define o primeiro turno disponível
        }
    }, [filteredSeries, availableTurnos, turno, serie, turnos]);
    

    useEffect(() => {
        if (!turno && availableTurnos.length > 0) {
            setTurno(availableTurnos[0]); // Define o primeiro turno disponível
        }
    }, [availableTurnos, turno]);
    

    useEffect(() => {
        // Atualizar turnos disponíveis com base nas séries acessíveis e planos
        const turnosSet = new Set();
        planos.forEach((plano) => {
            if (allowedSeries.includes(plano.serie)) {
                turnosSet.add(plano.turno);
            }
        });
        setAvailableTurnos([...turnosSet]);
    }, [planos, allowedSeries]);

    useEffect(() => {
        // Calcula os turnos disponíveis com base na série selecionada
        const turnosParaSerie = allowedSeries
            .map((serieOption, index) => {
                if (serieOption === serie) {
                    return allowedTurnos[index]; // Turnos correspondentes à série
                }
                return [];
            })
            .flat();
    
        // Se `allowedTurnos` estiver vazio ou `turnosParaSerie` não tiver valores, use todos os turnos
        const updatedTurnos = turnosParaSerie.length > 0 ? turnosParaSerie : turnos;
    
        setAvailableTurnos(updatedTurnos);
    
        // Atualiza o turno atual se não estiver incluído nos turnos disponíveis
        if (!updatedTurnos.includes(turno)) {
            setTurno(updatedTurnos[0] || ''); // Define o primeiro turno disponível ou vazio
        }
    }, [serie, allowedSeries, allowedTurnos, turnos, turno]);
    


    const handleSerieChange = (e) => {
        const newSerie = e.target.value;
        setSerie(newSerie);
    
        // Calcula os turnos disponíveis para a nova série
        const turnosParaSerie = allowedSeries
            .map((serieOption, index) => {
                if (serieOption === newSerie) {
                    return allowedTurnos[index];
                }
                return [];
            })
            .flat();
    
        // Use todos os turnos se `turnosParaSerie` estiver vazio
        const updatedTurnos = turnosParaSerie.length > 0 ? turnosParaSerie : turnos;
        setAvailableTurnos(updatedTurnos);
        setTurno(updatedTurnos[0] || ''); // Define o primeiro turno disponível ou vazio
    };
    

    const handleTurnoChange = (e) => {
        setTurno(e.target.value);
    };
    


    // Conteúdo do Dashboard
    const dashboardContent = (
        <div className={styles.bottomAreaInfo}>
            <div className={styles.allBottomAreaInfo}>
                <div className={styles.marginStart}>
                    <div className={styles.start}>
                        <div>
                            <p>Série</p>
                            <select value={serie} onChange={handleSerieChange}>
                                {filteredSeries.map((serieOption, index) => (
                                    <option key={index} value={serieOption}>{serieOption}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <p>Turno</p>
                            <select value={turno} onChange={handleTurnoChange}>
                                {availableTurnos.map((turnoOption, index) => (
                                    <option key={index} value={turnoOption}>{turnoOption}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <p>Ano</p>
                            <select value={ano} onChange={handleAnoChange}>
                                {anosDisponiveis.map((anoOption, index) => (
                                    <option key={index} value={anoOption}>{anoOption}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <p>Mês</p>
                            <select value={mes} onChange={handleMesChange}>
                                <option value="">Todos</option>
                                {mesesDisponiveis.map((mesOption, index) => (
                                    <option key={index} value={mesOption}>{mesOption}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <p>Matéria</p>
                            <select value={materia} onChange={handleMateriaChange}>
                                <option value="">Todas</option>
                                {materiasDisponiveis.map((materiaOption, index) => (
                                    <option key={index} value={materiaOption}>{materiaOption}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className={styles.endItems}>
                    {filteredPlanos.length === 0 ? (
                        <div className={styles.notFound}>
                            <p>Nenhuma Matéria Encontrada!</p>
                            <img src={img} alt='Nenhuma Matéria Encontrada' />
                        </div>
                    ) : (
                        <>
                            {filteredPlanos.map(plano => (
                                <div key={plano._id} className={styles.planeContainer}>
                                    <div className={styles.plane}>
                                        {(accessLevel === 'high' || accessLevel === 'professor') && (
                                            <div className={styles.actionButtons}>
                                                <button onClick={() => openEditPopup(plano)} className={styles.editButton} aria-label="Editar Plano">
                                                    <MdEdit />
                                                </button>
                                                <button onClick={() => handleDeleteClick(plano._id)} className={styles.deleteButton} aria-label="Deletar Plano">
                                                    <MdDelete />
                                                </button>
                                            </div>
                                        )}
                                        <div className={styles.headPlane}>
                                            <h2>{plano.titulo}</h2>
                                            <p>{plano.descricao}</p>
                                        </div>
                                        <div className={styles.planosList}>
                                            {plano.planejamento.map((planejamento, index) => (
                                                <div key={index} className={styles.plane}>
                                                    <div className={styles.headPlane}>
                                                        <h3>{planejamento.mes}</h3>
                                                        {planejamento.plano.map((planoItem, planoIndex) => (
                                                            <div key={planoIndex}>
                                                                <h4>{planoItem.materia}</h4>
                                                                <p>{planoItem?.descricao === '' ? "Adicione a descrição..." : planoItem?.descricao}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
            {
                showDeletePopup && (
                    <div className={styles.popupOverlay}>
                        <div className={styles.popup}>
                            <div className={styles.title}>
                                <h2>Confirmar Exclusão</h2>
                                <p>Tem certeza de que deseja deletar este plano?</p>
                            </div>
                            <div className={styles.popupActions}>
                                <button onClick={handleDeleteCancel}>Não</button>
                                <button onClick={handleDeleteConfirm}>Sim</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* Popup para Criar Plano */}
            <PopUpComponent
                title="Criar Plano"
                isOpen={isCreatePopupOpen}
                onClose={closeCreatePopup}
            >
                <PlanosComponentCreate
                    onSave={handleCreatePlan}
                    onClose={closeCreatePopup}
                    accessLevel={accessLevel}
                    allowedSeries={allowedSeries}
                    selectedSerie={serie}
                    selectedAno={ano}
                    selectedTurno={turno}
                />
            </PopUpComponent>
            {/* Popup para Editar Plano */}
            <PopUpComponent
                title="Editar Plano"
                isOpen={isEditPopupOpen}
                onClose={closeEditPopup}
            >
                <PlanosComponentEdit
                    plan={selectedPlan}
                    onSave={handleUpdatePlan}
                    onClose={closeEditPopup}
                />
            </PopUpComponent>
        </div>
    );

    const buttons = [];

    if (accessLevel === 'high' || accessLevel === 'professor') {
        if (accessLevel !== 'professor') {
            buttons.push({
                onClick: () => navigate('/planos/materias'),
                label: 'Adicionar Matérias',
                icon: LiaSchoolSolid,
            });
        }

        buttons.push({
            onClick: openCreatePopup,
            label: 'Adicionar Plano',
            icon: IoIosAddCircleOutline,
        });
    }

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={false}
                    namePage="Planos"
                    accessType='administrador'
                />
            } />
        </div>
    );
};

export default PlanosComponent;
