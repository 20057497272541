// BasicPageStructure.js
import React from 'react';
import styles from './BasicPageStructure.module.css';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowLeft } from 'react-icons/md';

const BasicPageStructure = ({ content, buttons, returnPage = false, returnPath = '/', returnLabel = 'Painel' , namePage = 'Painel', accessType = "administrador"}) => {
    return (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={`${styles.topAreaInfo} ${buttons.length === 0 ? styles.noButtons : ''}`} >
                    {/* Renderiza o link de retorno apenas se returnPage for true */}
                    {returnPage && (
                        <div className={styles.returnPainelArrow}>
                            <Link to={returnPath} className={styles.returnLink}>
                                <MdKeyboardArrowLeft className={styles.returnIcon} />
                                {returnLabel}
                            </Link>
                        </div>
                    )}
                    <div style={returnPage ? {} : { paddingTop: "20px" }}  className={styles.painel}>
                        <div className={styles.start}>
                            <h1>{namePage}</h1>
                            <p>{accessType}</p>
                        </div>
                        <div className={styles.end}>
                            {buttons.map((button, index) => (
                                <button
                                    key={index}
                                    onClick={button.onClick}
                                    className={`${styles.button} ${index === buttons.length - 1 ? styles.primaryButton : styles.defaultButton}`}
                                >
                                    {button.icon && <button.icon className={styles.icon} />}
                                    {button.label}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.bottomAreaInfo}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default BasicPageStructure;
