// CalendarioComponent.js
import React, { useState, useEffect } from 'react';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import InterfaceStructure from '../../../components/InterfaceStructure';
import styles from './CalendarioComponent.module.css';
import CalendarioViewComponent from './CalendarioView/CalendarioViewComponent';
import useTenantId from '../../../components/tenantId';
import moment from 'moment';
import apiAxios from '../../../api/apiAxios';
import { Link } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import CalendarioCategoryViewComponent from './CalendarioView/Category/CalendarioCategoryViewComponent';
import { BiCategory } from "react-icons/bi";
import PopUpComponent from '../../popUp/PopUpComponent';
import CalendarioCreateComponent from './CalendarioCreateComponent';

const CalendarioComponent = ({ itemsPages, accessLevel = 'high' }) => {  // Define o nível de acesso como "high" por padrão
  const itemsPagesList = itemsPages;

  const [date, setDate] = useState(new Date());
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [calendario, setCalendario] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showCreateEventPopup, setShowCreateEventPopup] = useState(false);

  const tenantId = useTenantId();

  const fetchCategorias = async () => {
    try {
      const response = await apiAxios.get(`/api/calendar/category/${tenantId}`);
      setCategorias(response.data);
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
    }
  };

  useEffect(() => {
    if (tenantId) {
      fetchCategorias();
    }
  }, [tenantId]);

  const fetchCalendario = async () => {
    try {
      const response = await apiAxios.get(`/api/calendar/all/${tenantId}`);
      setCalendario(response.data);
    } catch (error) {
      console.error('Erro ao buscar calendários:', error);
    }
  };

  useEffect(() => {
    fetchCalendario();
  }, [tenantId]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleViewToggle = (selectedDate) => {
    const eventDate = moment(selectedDate).format("YYYY-MM-DD");
    const events = calendario.filter(event => moment(event.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD") === eventDate);

    if (events.length > 0) {
      setDate(selectedDate);
      setIsViewOpen(true);
    } else {
      setIsViewOpen(false);
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const eventDate = moment(date).format("YYYY-MM-DD");
      const events = calendario.filter(event => moment(event.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD") === eventDate);

      if (events.length > 0) {
        return (
          <div className={styles.eventDay}>
            {events.map((event, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: event.categoriaColor,
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginLeft: '5px'
                }}
              ></div>
            ))}
          </div>
        );
      }
    }
    return null;
  };

  const handleDownloadPDF = async () => {
    const year = moment(date).format("YYYY"); // Ano da data visualizada no calendário
    const month = moment(date).format("MM"); // Mês da data visualizada no calendário

    try {
      const response = await apiAxios.get(
        `/api/calendar/generate-pdf/${tenantId}/${year}/${month}`,
        {
          responseType: "blob", // Recebe o arquivo como binário
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Calendario-${year}-${month}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Erro ao baixar o PDF:", error);
    }
  };

  const dashboardContent = (
    <div className={styles.all}>
      <div className={styles.home}>
        <div className={styles.topAreaInfo}>
          <div className={styles.start}>
            <h1>Calendário</h1>
            <p>Administrador</p>
          </div>
          {accessLevel === 'high' && (
            <div className={styles.end}>
              <Link
                style={{
                  background: "white",
                  border: "0.1px solid #cccccc",
                  color: "gray",
                  cursor: tenantId ? "pointer" : "not-allowed", // Cursor muda para indicar inatividade
                  opacity: tenantId ? 1 : 0.5, // Botão fica mais claro se inativo
                }}
                onClick={tenantId ? handleDownloadPDF : null} // Apenas executa a ação se o tenantId estiver disponível
              >
                📄 Baixar PDF
              </Link>
              <Link style={{ background: "white", border: "0.1px solid #cccccc", color: "gray" }} onClick={() => setShowAddCategoryModal(true)}>
                <BiCategory /> Gerenciar Categorias
              </Link>
              <Link style={{ cursor: "pointer" }} onClick={() => setShowCreateEventPopup(true)}>
                <IoIosAddCircleOutline /> Adicionar Calendário
              </Link>
            </div>
          )}
        </div>
        <div className={styles.bottomAreaItem}>
          <div className={styles.calendar}>
            <div className={styles.calendarWrapper}>
              <ReactCalendar
                onChange={handleDateChange}
                value={date}
                onClickDay={handleViewToggle}
                onActiveStartDateChange={({ activeStartDate }) => setDate(activeStartDate)} 
                prev2Label={null}
                next2Label={null}
                tileContent={tileContent}
                className={styles.reactCalendar}
                locale="pt-BR"
              />
            </div>
            {isViewOpen && (
              <CalendarioViewComponent
                date={date}
                events={calendario.filter(event => moment(event.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))}
                onClose={() => setIsViewOpen(false)}
                accessLevel={accessLevel} // Passa o nível de acesso para o CalendarioViewComponent
              />
            )}
            {showAddCategoryModal && <CalendarioCategoryViewComponent onClose={() => setShowAddCategoryModal(false)} />}
            {showCreateEventPopup && (
              <PopUpComponent title='Criar Calendário' isOpen={showCreateEventPopup} onClose={() => setShowCreateEventPopup(false)}>
                <CalendarioCreateComponent />
              </PopUpComponent>
            )}
          </div>
          <div className={styles.categories}>
            <h2>Categorias</h2>
            {categorias.map((categoria) => (
              <div className={styles.item} key={categoria.id}>
                <div className={styles.colorBoll} style={{ background: categoria.category.color }}></div>
                <div className={styles.nameCat}>{categoria.category.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
  );
};

export default CalendarioComponent;
