import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/Administrador/DashboardPage';
import Usuarios from './pages/Administrador/Usuarios';
import NotFoundPage from './pages/NotFoundPage';
import PerfilPage from './pages/Administrador/PerfilPage';
import Requests from './pages/Administrador/Requests';
import HomeProfessor from './pages/Professor/HomeProfessor';
import AtividadesAdminPage from './pages/Administrador/atividades/AtividadesAdminPage';
import AgendaAdminPage from './pages/Administrador/agenda/AgendaAdminPage';
import PlanosAdminPage from './pages/Administrador/planos/PlanosAdminPage';
import CreateAtividade from './pages/Administrador/atividades/CreateAtividade';
import InformesAdminPage from './pages/Administrador/Informes/InformesAdminPage';
import CardapioPage from './pages/Administrador/cardapio/CardapioPage';
import CardapioPageCreate from './pages/Administrador/cardapio/CardapioPageCreate';
import CalendarioPage from './pages/Administrador/Calendario/CalendarioPage';
import CalendarioCreatePage from './pages/Administrador/Calendario/CalendarioCreatePage';
import CalendarioCategoryCreatePage from './pages/Administrador/Calendario/CalendarioCategoryCreatePage';
import AgendaAdminCreate from './pages/Administrador/agenda/AgendaAdminCreate';
import ChatPage from './pages/Administrador/Chat/ChatPage';
import { useEffect, useState } from 'react';
import apiAxios from './api/apiAxios';
import Loading from './components/Loading/Loading';
import HomeSecretaria from './pages/secretaria/HomeSecretaria';
import RequestsSecretaria from './pages/secretaria/RequestsSecretaria';
import UsuariosSecretaria from './pages/secretaria/UsuariosSecretaria';
import AgendaSecretariaPage from './pages/secretaria/agenda/AgendaSecretariaPage';
import AgendaSecretariaCreate from './pages/secretaria/agenda/AgendaSecretariaCreate';
import MateriasSecretaria from './pages/secretaria/Materia/MateriasAgendaSecretaria';
import CalendarioSecretariaPage from './pages/secretaria/Calendario/CalendarioSecretariaPage';
import CalendarioSecretariaCreatePage from './pages/secretaria/Calendario/CalendarioSecretariaCreatePage';
import CalendarioSecretariaCategoryCreatePage from './pages/secretaria/Calendario/CalendarioSecretariaCategoryCreatePage';
import CardapioSecretariaPage from './pages/secretaria/cardapio/CardapioSecretariaPage';
import CardapioSecretariaPageCreate from './pages/secretaria/cardapio/CardapioSecretariaPageCreate';
import InformesSecretariaPage from './pages/secretaria/Informes/InformesSecretariaPage';
import AtividadesSecretariaPage from './pages/secretaria/atividades/AtividadesSecretariaPage';
import CreateSecretariaAtividade from './pages/secretaria/atividades/CreateSecretariaAtividade';
import ChatSecretariaPage from './pages/secretaria/Chat/ChatSecretariaPage';
import CalendarioEditPage from './pages/Administrador/Calendario/CalendarioEditPage';
import CalendarioSecretariaEditPage from './pages/secretaria/Calendario/CalendarioSecretariaEditPage';
import EditUserPage from './pages/Administrador/EditUserPage';
import FichasPage from './pages/Administrador/fichas/FichasPage';
import FichaReceivedPage from './pages/Administrador/fichas/response/FichaReceivedPage';
import FichasSecretariaPage from './pages/secretaria/fichas/FichasSecretariaPage';
import FichaSecretariaReceivedPage from './pages/secretaria/fichas/response/FichaSecretariaReceivedPage';
import FichaResponsePage from './pages/FichaResponsePage';
import PlanosSecretariaPage from './pages/secretaria/planos/PlanosSecretariaPage';
import HorariosPage from './pages/Administrador/horarios/HorariosPage';
import HomeCoordenacao from './pages/Coordenacao/HomeCoordenacao';
import HorariosCoordenacaoPage from './pages/Coordenacao/horarios/HorariosCoordenacaoPage';
import HorariosProfessorPage from './pages/Professor/horarios/HorariosProfessorPage';
import CalendarioCoordenacaoPage from './pages/Coordenacao/Calendario/CalendarioCoordenacaoPage';
import CalendarioProfessorPage from './pages/Professor/Calendario/CalendarioProfessorPage';
import UsuariosCoordenacao from './pages/Coordenacao/UsuariosCoordenacao';
import RequestsCoordenacao from './pages/Coordenacao/RequestsCoordenacao';
import EditCoordenacaoUserPage from './pages/Coordenacao/EditCoordenacaoUserPage';
import PerfilCoordenacaoPage from './pages/Coordenacao/PerfilCoordenacaoPage';
import PerfilSecretariaPage from './pages/secretaria/PerfilSecretariaPage';
import PerfilProfessorPage from './pages/Professor/PerfilProfessorPage';
import HorariosSecretariaPage from './pages/secretaria/horarios/HorariosSecretariaPage';
import AssinaturaPage from './pages/Administrador/assinatura/AssinaturaPage';
import HomePage from './pages/AdminMaster/HomePage';
import TenantManagementPage from './pages/AdminMaster/tenants/TenantManagementPage';
import CardapioNutricionistaPage from './pages/nutricionista/cardapio/CardapioNutricionistaPage';
import HomeNutricionista from './pages/nutricionista/HomeNutricionista';
import CardapioNutricionistaPageCreate from './pages/nutricionista/cardapio/CardapioNutricionistaPageCreate';
import FrequenciaAdminPage from './pages/Administrador/frequencia/FrequenciaAdminPage';
import PlanosProfessorPage from './pages/Professor/planos/PlanosProfessorPage';
import MateriasAgenda from './pages/Administrador/Materia/MateriasAgenda';
import MateriasPlanos from './pages/Administrador/Materia/MateriasPlanos';
import PlanosCoordenacaoPage from './pages/Coordenacao/planos/PlanosCoordenacaoPage';
import FrequenciaProfessorPage from './pages/Professor/frequencia/FrequenciaProfessorPage';

import MateriasPlanosCoordenacao from './pages/Coordenacao/Materia/MateriasPlanosCoordenacao'
import MateriasAgendaCoordenacao from './pages/Coordenacao/Materia/MateriasAgendaCoordenacao'
import FrequenciaSecretariaPage from './pages/secretaria/Frequencia/FrequenciaSecretariaPage';
import BoletimAdminPage from './pages/Administrador/Boletim/BoletimAdminPage';
import BoletinsAlunosPage from './pages/Administrador/Boletim/BoletinsAlunos/BoletinsAlunosPage';
import BoletimPage from './pages/Administrador/Boletim/Boletim/BoletimPage';
import AgendaProfessorPage from './pages/Professor/Agenda/AgendaProfessorPage';
import BoletimProfessorPage from './pages/Professor/boletim/BoletimProfessorPage';
import BoletinsAlunosProfessorPage from './pages/Professor/boletim/boletinsAlunos/BoletinsAlunosProfessorPage';
import BoletimProfessorDetailPage from './pages/Professor/boletim/boletim/BoletimProfessorDetailPage';
import BoletimCoordenacaoPage from './pages/Coordenacao/Boletim/BoletimCoordenacaoPage';
import BoletinsCoordenacaoAlunosPage from './pages/Coordenacao/Boletim/BoletinsAlunos/BoletinsCoordenacaoAlunosPage';
import BoletimCoordenacaoDetailPage from './pages/Coordenacao/Boletim/Boletim/BoletimCoordenacaoDetailPage';
import FrequenciaCoordenacaoPage from './pages/Coordenacao/frequencia/FrequenciaCoordenacaoPage';
import AgendaCoordenacaoPage from './pages/Coordenacao/agenda/AgendaCoordenacaoPage';
import BoletimSecretariaPage from './pages/secretaria/Boletim/BoletimSecretariaPage';
import BoletinsSecretariaAlunosPage from './pages/secretaria/Boletim/BoletinsAlunos/BoletinsSecretariaAlunosPage';
import BoletimSecretariaDetailPage from './pages/secretaria/Boletim/Boletim/BoletimSecretariaDetailPage';

import MateriasAgendaSecretaria from './pages/secretaria/Materia/MateriasAgendaSecretaria';
import MateriasPlanosSecretaria from './pages/secretaria/Materia/MateriasPlanosSecretaria';

function App() {
  const isAuthenticated = useSelector(state => state.user.userInfo?.token);
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = isAuthenticated && JSON.parse(atob(userInfo.token.split('.')[1]));
  const tenantId = tokenPayload?.tenantId;
  const Id = tokenPayload?._id;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);

  const fetchUser = async () => {
    try {
      const response = await apiAxios.get(`/api/user/users/${tenantId}`);
      const user = response?.data?.users?.find((u) => u._id === Id);
      console.log('User fetched:', user);
      setUser(user);
    } catch (error) {
      console.error('Error retrieving user:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [tenantId, Id]);

  const handlePageChange = () => {
    setPageLoading(true);
    setTimeout(() => setPageLoading(false), 3500); // Simula o tempo de carregamento
  };

  if (loading) {
    return <Loading />;
  }

  const isAdmin = user?.isAdminMaster;
  const isDiretor = user?.isAdmin;
  const isCoordenaçao = user?.isCoordenaçao;
  const isSecretaria = user?.isSecretaria;
  const isProfessor = user?.isProfessor;
  const isNutricionista = user?.isNutricionista;

  return (
    <Router>
      <RouteChangeHandler onChange={handlePageChange} />
      {pageLoading && <Loading />}
      <Routes>
        {isAuthenticated ? (
          <>
            {isAdmin && (
              <>
                <Route path="/" element={<HomePage />} />
                <Route path="/manager-tenants" element={<TenantManagementPage />} />
              </>
            )}

            {isDiretor && (
              <>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/user/:id" element={<EditUserPage />} />
                <Route path="/requests" element={<Requests />} />
                <Route path="/atividades" element={<AtividadesAdminPage />} />
                <Route path="/atividades/create" element={<CreateAtividade />} />
                <Route path="/perfil/:id" element={<PerfilPage user={user} />} />
                <Route path="/agenda" element={<AgendaAdminPage />} />
                <Route path="/agenda/create" element={<AgendaAdminCreate />} />
                <Route path="/agenda/materias" element={<MateriasAgenda />} />
                <Route path="/planos/materias" element={<MateriasPlanos />} />
                <Route path="/planos" element={<PlanosAdminPage />} />
                <Route path='/informes' element={<InformesAdminPage />} />
                <Route path='/cardapio' element={<CardapioPage />} />
                <Route path='/cardapio/create' element={<CardapioPageCreate />} />
                <Route path='/calendario' element={<CalendarioPage />} />
                <Route path='/calendario/create' element={<CalendarioCreatePage />} />
                <Route path='/calendario/edit/:id' element={<CalendarioEditPage />} />
                <Route path='/calendario/category/create' element={<CalendarioCategoryCreatePage />} />
                <Route path='/chat' element={<ChatPage />} />
                <Route path='/fichas' element={<FichasPage />} />
                <Route path='/fichas/received/:formId' element={<FichaReceivedPage />} />
                <Route path='/horarios' element={<HorariosPage />} />
                <Route path='/signature' element={<AssinaturaPage />} />
                <Route path='/frequencia' element={<FrequenciaAdminPage />} />

                <Route path='/Boletim' element={<BoletimAdminPage />} />
                <Route path='/Boletins/:id' element={<BoletinsAlunosPage />} />
                <Route path='/boletins/:boletimid/aluno/:id' element={<BoletimPage />} />
              </>
            )}

            {isCoordenaçao && (
              <>
                <Route path="/" element={<HomeCoordenacao />} />
                <Route path="/usuarios" element={<UsuariosCoordenacao />} />
                <Route path="/perfil/:id" element={<PerfilCoordenacaoPage user={user} />} />
                <Route path="/user/:id" element={<EditCoordenacaoUserPage />} />
                <Route path="/requests" element={<RequestsCoordenacao />} />
                <Route path='/chat' element={<ChatPage />} />

                <Route path='/horarios' element={<HorariosCoordenacaoPage />} />

                <Route path='/calendario' element={<CalendarioCoordenacaoPage />} />

                <Route path="/agenda/materias" element={<MateriasAgendaCoordenacao />} />

                <Route path="/agenda" element={<AgendaCoordenacaoPage />} />

                <Route path='/frequencia' element={<FrequenciaCoordenacaoPage />} />

                <Route path="/planos" element={<PlanosCoordenacaoPage />} />
                <Route path="/planos/materias" element={<MateriasPlanosCoordenacao />} />

                <Route path='/Boletim' element={<BoletimCoordenacaoPage />} />
                <Route path='/Boletins/:id' element={<BoletinsCoordenacaoAlunosPage />} />
                <Route path='/boletins/:boletimid/aluno/:id' element={<BoletimCoordenacaoDetailPage />} />
              </>
            )}

            {isSecretaria && (
              <>
                <Route path="/" element={<HomeSecretaria />} />
                <Route path="/requests" element={<RequestsSecretaria />} />
                <Route path="/usuarios" element={<UsuariosSecretaria />} />
                <Route path="/perfil/:id" element={<PerfilSecretariaPage user={user} />} />
                <Route path="/agenda" element={<AgendaSecretariaPage />} />
                <Route path="/agenda/create" element={<AgendaSecretariaCreate />} />
                <Route path="/agenda/materias" element={<MateriasAgendaSecretaria />} />

                <Route path="/planos" element={<PlanosSecretariaPage />} />
                <Route path="/planos/materias" element={<MateriasPlanosSecretaria />} />
                <Route path='/calendario' element={<CalendarioSecretariaPage />} />
                <Route path='/calendario/create' element={<CalendarioSecretariaCreatePage />} />
                <Route path='/calendario/category/create' element={<CalendarioSecretariaCategoryCreatePage />} />
                <Route path='/cardapio' element={<CardapioSecretariaPage />} />
                <Route path='/cardapio/create' element={<CardapioSecretariaPageCreate />} />
                <Route path='/informes' element={<InformesSecretariaPage />} />
                <Route path='/calendario/edit/:id' element={<CalendarioSecretariaEditPage />} />
                <Route path="/atividades" element={<AtividadesSecretariaPage />} />
                <Route path="/atividades/create" element={<CreateSecretariaAtividade />} />
                <Route path='/chat' element={<ChatSecretariaPage />} />
                <Route path='/fichas' element={<FichasSecretariaPage />} />
                <Route path='/fichas/received/:formId' element={<FichaSecretariaReceivedPage />} />
                <Route path="/horarios" element={<HorariosSecretariaPage />} />
                <Route path="/perfil/:id" element={<PerfilPage user={user} />} />
                <Route path='/frequencia' element={<FrequenciaSecretariaPage />} />

                <Route path='/Boletim' element={<BoletimSecretariaPage />} />
                <Route path='/Boletins/:id' element={<BoletinsSecretariaAlunosPage />} />
                <Route path='/boletins/:boletimid/aluno/:id' element={<BoletimSecretariaDetailPage />} />
              </>
            )}

            {isProfessor && (
              <>
                <Route path="/" element={<HomeProfessor />} />
                <Route path="/horarios" element={<HorariosProfessorPage />} />
                <Route path='/calendario' element={<CalendarioProfessorPage />} />
                <Route path="/perfil/:id" element={<PerfilProfessorPage user={user} />} />
                <Route path="/planos" element={<PlanosProfessorPage />} />
                <Route path='/frequencia' element={<FrequenciaProfessorPage />} />
                <Route path="/agenda" element={<AgendaProfessorPage />} />
                <Route path="/agenda/create" element={<AgendaAdminCreate />} />
                <Route path="/agenda/materias" element={<MateriasAgenda />} />
                <Route path='/Boletim' element={<BoletimProfessorPage />} />
                <Route path='/Boletins/:id' element={<BoletinsAlunosProfessorPage />} />
                <Route path='/boletins/:boletimid/aluno/:id' element={<BoletimProfessorDetailPage />} />
              </>
            )}

            {isNutricionista && (
              <>
                <Route path="/" element={<HomeNutricionista />} />
                <Route path='/cardapio' element={<CardapioNutricionistaPage />} />
                <Route path='/cardapio/create' element={<CardapioNutricionistaPageCreate />} />
                <Route path="/perfil/:id" element={<PerfilPage user={user} />} />
              </>
            )}
          </>
        ) : (
          <>
            <Route path='/' element={<LoginPage />} />
          </>
        )}
        <Route path="/ficha/public/:formId" element={<FichaResponsePage />} />
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

function RouteChangeHandler({ onChange }) {
  const location = useLocation();

  useEffect(() => {
    onChange();
  }, [location]);

  return null;
}

export default App;
