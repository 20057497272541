import React, { useEffect, useState, useRef } from 'react';
import BasicPageStructure from '../../../BasicPageStructure';
import useTenantId from '../../../tenantId';
import { useParams } from 'react-router-dom';
import InterfaceStructure from '../../../InterfaceStructure';
import apiAxios from '../../../../api/apiAxios';
import Loading from '../../../Loading/Loading';
import BoletimComponent from './BoletimComponent';  // Importando o componente do boletim
import BoletimInfantilComponent from './BoletimInfantilComponent';  // Importando o componente do relatório infantil
import styles from './BoletimAlunoDetailComponent.module.css';

const BoletimAlunoDetailComponent = ({ itemsPages }) => {
    const [boletimAluno, setBoletimAluno] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const tenantId = useTenantId();
    const isCreatingRef = useRef(false);

    const { boletimid, id } = useParams();
    const boletimId = boletimid;
    const alunoId = id;

    useEffect(() => {
        const fetchBoletimAluno = async () => {
            setLoading(true);
            try {
                const response = await apiAxios.get('/api/boletims/boletins/aluno', {
                    params: { tenantId, boletimId, alunoId },
                });

                if (response.data && response.data.data.length > 0) {
                    setBoletimAluno(response.data.data[0]);
                } else {
                    if (!isCreatingRef.current) {
                        isCreatingRef.current = true;
                        await createBoletimAluno();
                    }
                }
            } catch (err) {
                console.error('Erro ao buscar ou criar boletim do aluno:', err.response?.data || err.message);
                setError('Erro ao carregar o boletim do aluno.');
            } finally {
                setLoading(false);
            }
        };

        fetchBoletimAluno();
    }, [tenantId, boletimId, alunoId]);

    const createBoletimAluno = async () => {
        try {
            const boletimResponse = await apiAxios.get(`/api/boletims/boletins/${boletimId}`);
            const alunoResponse = await apiAxios.get(`/api/user/user/${alunoId}`);
            const aluno = alunoResponse.data.user;
    
            const serie = aluno.alunoInfo?.serie || "Sem série";
            const nivelEnsino = boletimResponse?.data?.data?.nivelEnsino;
            const periodType = boletimResponse?.data?.data?.periodType?.toLowerCase();
    
            console.log("Tipo de período recebido:", periodType);
    
            const getPeriodos = (type) => {
                const normalizedType = type?.toLowerCase();
                switch (normalizedType) {
                    case "bimestre":
                        return ["1º Bimestre", "2º Bimestre", "3º Bimestre", "4º Bimestre"];
                    case "semestre":
                        return ["1º Semestre", "2º Semestre"];
                    case "anual":
                        return ["Ano"];
                    default:
                        return [];
                }
            };
    
            const periodos = getPeriodos(periodType);
    
            if (!periodos.length) {
                throw new Error(`Tipo de período inválido ou não definido: ${periodType}`);
            }
    
            if (nivelEnsino === "Educação Infantil") {
                const relatorio = {
                    tenantId,
                    boletimId,
                    alunoId,
                    matricula: aluno.matricula || "Sem matrícula",
                    serie,
                    turno: aluno.alunoInfo?.turno || "Sem turno",
                    nivelEnsino,
                    relatorios: boletimResponse?.data?.data?.areas?.map((areaGeral) => ({
                        areaGeral: {
                            nome: areaGeral.nome || "Área Geral não definida",
                            descricao: areaGeral.descricao || "",
                        },
                        areasEspecificas: [
                            {
                                competencias: areaGeral.competencias.map((competencia) => ({
                                    competencia: competencia || "Competência não definida",
                                    periodos: periodos.map((periodo) => ({
                                        periodo,
                                        tipo: "EP", // Valor padrão "Em Progresso"
                                    })),
                                })),
                            },
                        ],
                    })) || [],
                };
    
                const createResponse = await apiAxios.post("/api/boletims/boletins/aluno", relatorio);
    
                console.log(">>", relatorio);
                console.log(">>", createResponse);
                setBoletimAluno(createResponse.data);
            } else {
                const materiasResponse = await fetchMateriasBySerie(serie);
                console.log("Estrutura de materiasResponse:", JSON.stringify(materiasResponse, null, 2));
    
                const materias = Array.isArray(materiasResponse) && materiasResponse[0]?.materia
                    ? materiasResponse[0].materia.map((materiaObj) => materiaObj.materia)
                    : [];
    
                console.log("Matérias extraídas:", materias);
    
                const tiposAvaliacao = boletimResponse?.data?.data?.tiposAvaliacao || ["AV1", "AV2", "AV3"];
    
                // Criar o array `notas` com a estrutura correta
                const notas = materias.map((materiaNome) => ({
                    materia: materiaNome, // Nome da matéria como string
                    avaliacoes: tiposAvaliacao.map((tipo) => ({
                        tipo, // Tipo de avaliação
                        nota: null, // Nota inicial como null
                    })),
                    media: null, // Média inicial
                    status: "Pendente", // Status inicial
                }));
    
                const novoBoletimAluno = {
                    tenantId,
                    boletimId,
                    alunoId,
                    matricula: aluno.matricula || "Sem matrícula",
                    serie,
                    turno: aluno.alunoInfo?.turno || "Sem turno",
                    nivelEnsino,
                    notas, // Cada objeto representa uma matéria
                };
    
                console.log("Estrutura final de notas corrigida:", notas);
                console.log("Novo boletim aluno corrigido:", novoBoletimAluno);
    
                const createResponse = await apiAxios.post("/api/boletims/boletins/aluno", novoBoletimAluno);
                const updatedResponse = await apiAxios.get("/api/boletims/boletins/aluno", {
                    params: { tenantId, boletimId, alunoId },
                });
                setBoletimAluno(updatedResponse.data.data[0]);
            }
        } catch (err) {
            console.error("Erro ao criar boletim do aluno:", err.response?.data || err.message);
            setError("Erro ao criar boletim do aluno.");
        } finally {
            isCreatingRef.current = false;
        }
    };
    


    console.log(boletimAluno)

    const fetchMateriasBySerie = async (serie) => {
        try {
            const response = await apiAxios.get(`/api/mat/materias`, {
                params: { tenantId, serie },
            });
            return response.data || [];
        } catch (err) {
            console.error('Erro ao buscar matérias pela série:', err.response?.data || err.message);
            return [
                { materia: 'Matemática', description: 'Matéria padrão', color: '#FF0000' },
                { materia: 'Português', description: 'Matéria padrão', color: '#00FF00' },
            ];
        }
    };

    if (error) {
        return <p>{error}</p>;
    }

    const dashboardContent = (
        <div className={styles.container}>
            {loading ? (
                <Loading />
            ) : !boletimAluno ? (
                <p>Boletim ou Relatório não encontrado.</p>
            ) : (
                <>
                    <h2 className={styles.boletimTitulo}>Detalhes do Boletim ou Relatório</h2>
                    <div className={styles.alunoInfo}>
                        <p><strong>Nome:</strong> {boletimAluno?.alunoId?.firstName + " " + boletimAluno?.alunoId?.lastName}</p>
                        <p><strong>Matrícula:</strong> {boletimAluno.matricula}</p>
                        <p><strong>Série:</strong> {boletimAluno.serie}</p>
                        <p><strong>Turno:</strong> {boletimAluno.turno}</p>
                        <p><strong>Nível de Ensino:</strong> {boletimAluno.nivelEnsino}</p>
                    </div>
                    {boletimAluno.nivelEnsino === 'Educação Infantil' ? (
                        <BoletimInfantilComponent
                            boletimAluno={boletimAluno}
                            tenantId={tenantId}
                            boletimId={boletimId}
                            alunoId={alunoId}
                        />
                    ) : boletimAluno.notas && boletimAluno.notas.length > 0 ? (
                        <BoletimComponent
                            boletimAluno={boletimAluno}
                            tenantId={tenantId}
                            boletimId={boletimId}
                            alunoId={alunoId}
                        />
                    ) : (
                        <p>Nenhuma nota disponível para este boletim.</p>
                    )}
                </>
            )}
        </div>
    );


    return (
        <InterfaceStructure
            itemsPages={itemsPages || []}
            dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={[]}
                    returnPage={true}
                    returnLabel="Voltar"
                    returnPath={`/boletins/${boletimId}`}
                    namePage="Detalhes do Boletim"
                    accessType="administrador"
                />
            }
        />
    );
};

export default BoletimAlunoDetailComponent;
