import React, { useState, useEffect } from 'react';
import InterfaceStructure from '../../components/InterfaceStructure';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from "./HomeProfessor.module.css";
import apiAxios from '../../api/apiAxios';
import useTenantId from '../../components/tenantId';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';

const HomeProfessor = () => {
    const tenantId = useTenantId();
    const [informes, setInformes] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    // Fetch informes from API
    useEffect(() => {
        const fetchInformes = async () => {
            try {
                const response = await apiAxios.get(`/api/inf/${tenantId}`);
                if (response && response.data) {
                    setInformes(response.data);
                } else {
                    console.error('Erro ao buscar informes: Resposta inválida da API');
                }
            } catch (error) {
                console.error(`Erro ao buscar informes: ${error.message}`);
            }
        };

        fetchInformes();
    }, [tenantId]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const renderInformes = () => (
        informes.map((informe) => (
            <div className={styles.sliderContainer} key={informe._id}>
                <img src={informe.imagem} alt={informe.titulo} />
                <div className={styles.overlay}>
                    <div className={styles.info}>
                        <div className={styles.topico}>
                            {informe.topico}
                        </div>
                        <h3 className={styles.overlayTitle}>{informe.titulo}</h3>
                        <p className={styles.overlayDescription}>{informe.descricao}</p>
                    </div>
                </div>
            </div>
        ))
    );


    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.grid}>
                    {informes.length > 0 ? (
                        <Slider {...settings} className={styles.sliderHome}>
                            {renderInformes()}
                        </Slider>
                    ) : (
                        <p>Nenhum informe disponível.</p>
                    )}
                </div>
            </div>
        </div>
    );

    const itemsPagesList = itemsPagesWithExclusions('Painel', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio']);

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
        </div>
    );
};

export default HomeProfessor;
