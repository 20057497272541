import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import MateriasComponent from '../../../components/pagesComponents/materiasComponents/MateriasComponent';

const MateriasAgendaSecretaria = () => {
    const itemsPagesList = itemsPagesWithExclusions('', ['Atividades', 'Chat']);
    return (
      <>
        <MateriasComponent itemsPages={itemsPagesList} returnLink={"/planos"} returnLinkName={"Planos"} />
      </>
    )
}

export default MateriasAgendaSecretaria;
