import React, { useEffect, useState } from 'react';
import styles from './PerfilProfessorPage.module.css';
import InterfaceStructure from '../../components/InterfaceStructure';
import { MdCalendarToday, MdLocationSearching, MdMailOutline, MdPermIdentity, MdPhoneAndroid, MdPublish } from 'react-icons/md';
import apiAxios from '../../api/apiAxios';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../components/FormatNumber';
import { useSnackbar } from 'notistack';

import { getStorage, ref, listAll, deleteObject, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';

const PerfilProfessorPage = () => {
    const { enqueueSnackbar } = useSnackbar(); // Hook para usar notificações
    const [users, setUsers] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [CPF, setCPF] = useState('');
    const [img, setImg] = useState(null);

    const userInfo = useSelector(state => state.user.userInfo);
    const professor = userInfo.isProfessor;
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

    const [AdminMaster, setAdminMaster] = useState();
    const [admin, setAdmin] = useState();
    const [coordenacao, setCoordenacao] = useState();
    const [secretaria, setSecretaria] = useState();
    const [teacher, setTeacher] = useState();
    const [nutricionista, setNutricionista] = useState();

    // Estados para a nova senha
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const itemsPagesList = itemsPagesWithExclusions('', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio']);


    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await apiAxios.get(`api/user/user/${tokenPayload._id}`);
                const userData = res.data.user;
                setUsers(userData);
                setFirstName(userData.firstName);
                setLastName(userData.lastName);
                setEmail(userData.email);
                setPhoneNumber(userData.phoneNumber);
                setCPF(userData.CPF);
                setAdminMaster(userData?.isAdminMaster);
                setAdmin(userData?.isAdmin);
                setCoordenacao(userData?.isCoordenaçao);
                setSecretaria(userData?.isSecretaria);
                setTeacher(userData?.isProfessor);
                setNutricionista(userData?.isNutricionista);
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Error fetching user data.", { variant: 'error' });
            }
        };
        getUsers();
    }, [tokenPayload._id, enqueueSnackbar]);

    let Hierarchy = '';

    if (AdminMaster) {
        Hierarchy = 'Gestor(a)';
    } else if (admin) {
        Hierarchy = 'Diretor(a)';
    } else if (coordenacao) {
        Hierarchy = 'Coordenador(a)';
    } else if (secretaria) {
        Hierarchy = 'Secretaria(o)';
    } else if (teacher) {
        Hierarchy = 'Professor(a)';
    } else if (nutricionista) {
        Hierarchy = 'Nutricionista(a)';
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const updateData = {
                firstName,
                lastName,
                email,
                phoneNumber,
                CPF,
                img
            };

            const response = await apiAxios.put(`api/user/user/${tokenPayload._id}`, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Atualize o estado com os novos dados do usuário
            setUsers((prevUsers) => ({
                ...prevUsers,
                firstName,
                lastName,
                email,
                phoneNumber,
                CPF,
                img
            }));

            enqueueSnackbar("User data updated successfully!", { variant: 'success' });
        } catch (error) {
            console.error("Error updating user:", error);
            enqueueSnackbar("Error updating user data.", { variant: 'error' });
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const storage = getStorage(app);
            const userImagesRef = ref(storage, 'userImages/');

            try {
                // Liste todos os arquivos no diretório 'userImages'
                const listResult = await listAll(userImagesRef);

                // Encontre o arquivo antigo correspondente ao email
                const oldImageRef = listResult.items.find(itemRef => itemRef.name.startsWith(`${email}-`));
                if (oldImageRef) {
                    // Exclua o arquivo antigo
                    await deleteObject(oldImageRef);
                }

                // Crie uma referência para o novo arquivo
                const newImageRef = ref(storage, `userImages/${email}-${Date.now()}`);

                // Faça o upload da nova imagem
                await uploadBytes(newImageRef, file);
                const downloadURL = await getDownloadURL(newImageRef);

                // Atualize o estado com a nova URL da imagem
                setImg(downloadURL);
                enqueueSnackbar("Image uploaded successfully!", { variant: 'success' });
            } catch (error) {
                console.error("Error handling image change:", error);
                enqueueSnackbar("Error uploading image.", { variant: 'error' });
            }
        }
    };

    // Função para atualizar a senha
    const handlePasswordUpdate = async () => {
        if (newPassword !== confirmPassword) {
            enqueueSnackbar("As senhas não coincidem!", { variant: 'error' });
            return;
        }

        try {
            const response = await apiAxios.put(`/api/user/user/${tokenPayload._id}/password`, {
                password: newPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status === 'success') {
                enqueueSnackbar("Senha atualizada com sucesso!", { variant: 'success' });
            } else {
                enqueueSnackbar(response.data.message || "Erro ao atualizar a senha", { variant: 'error' });
            }
        } catch (error) {
            console.error("Erro ao atualizar a senha:", error);
            enqueueSnackbar("Erro interno ao atualizar a senha.", { variant: 'error' });
        }
    };

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.userTitleContainer}>
                    <h1 className={styles.userTitle}>Perfil</h1>
                </div>
                <div className={styles.userContainer}>
                    <div className={styles.userShow}>
                        {users && (
                            <>
                                <div className={styles.userShowTop}>
                                    <img
                                        src={img || users.img || 'default-image-url'} // Use a URL padrão se img estiver nulo
                                        alt=""
                                        className={styles.userShowImg}
                                    />
                                    <div className={styles.userShowTopTitle}>
                                        <span className={styles.userShowUsername}>{users.firstName}</span>
                                        <span className={styles.userShowUserTitle}>{Hierarchy}</span>
                                    </div>
                                </div>
                                <div className={styles.userShowBottom}>
                                    <span className={styles.userShowTitle}>Detalhes de Conta</span>
                                    <div className={styles.userShowInfo}>
                                        <MdPermIdentity className={styles.userShowIcon} />
                                        <span className={styles.userShowInfoTitle}>{users.firstName + " " + users.lastName}</span>
                                    </div>
                                    <div className={styles.userShowInfo}>
                                        <MdCalendarToday className={styles.userShowIcon} />
                                        <span className={styles.userShowInfoTitle}>10.12.1999</span>
                                    </div>
                                    <span className={styles.userShowTitle}>Detalhes de contato</span>
                                    <div className={styles.userShowInfo}>
                                        <MdPhoneAndroid className={styles.userShowIcon} />
                                        <span className={styles.userShowInfoTitle}>{formatPhoneNumber(users.phoneNumber)}</span>
                                    </div>
                                    <div className={styles.userShowInfo}>
                                        <MdMailOutline className={styles.userShowIcon} />
                                        <span className={styles.userShowInfoTitle}>{users.email}</span>
                                    </div>
                                    <div className={styles.userShowInfo}>
                                        <MdLocationSearching className={styles.userShowIcon} />
                                        <span className={styles.userShowInfoTitle}>Fortaleza | BR</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.userUpdate}>
                        <span className={styles.userUpdateTitle}>Editar</span>
                        <form className={styles.userUpdateForm} onSubmit={handleUpdate}>
                            <div className={styles.userUpdateLeft}>
                                <div className={styles.userUpdateItem}>
                                    <label>Nome</label>
                                    <input
                                        type="text"
                                        placeholder="Nome"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className={styles.userUpdateInput}
                                    />
                                </div>
                                <div className={styles.userUpdateItem}>
                                    <label>Sobrenome</label>
                                    <input
                                        type="text"
                                        placeholder="Sobrenome"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className={styles.userUpdateInput}
                                    />
                                </div>
                                <div className={styles.userUpdateItem}>
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={styles.userUpdateInput}
                                    />
                                </div>
                                <div className={styles.userUpdateItem}>
                                    <label>Telefone</label>
                                    <input
                                        type="text"
                                        placeholder="Telefone"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        className={styles.userUpdateInput}
                                    />
                                </div>
                                <div className={styles.userUpdateItem}>
                                    <label>CPF</label>
                                    <input
                                        type="text"
                                        placeholder="CPF"
                                        value={CPF}
                                        onChange={(e) => setCPF(e.target.value)}
                                        className={styles.userUpdateInput}
                                    />
                                </div>
                            </div>
                            <div className={styles.userUpdateRight}>
                                <div className={styles.userUpdateUpload}>
                                    <img
                                        className={styles.userUpdateImg}
                                        src={img || users?.img || 'default-image-url'} // Use a URL padrão se img estiver nulo
                                        alt=""
                                    />
                                    <label htmlFor="file">
                                        <MdPublish className={styles.userUpdateIcon} />
                                    </label>
                                    <input
                                        type="file"
                                        id="file"
                                        style={{ display: "none" }}
                                        onChange={handleImageChange}
                                    />
                                </div>

                                <button className={styles.userUpdateButton} type="submit">Update</button>

                            </div>
                        </form>
                    </div>
                </div>
                <div className={styles.passwordUpdateSection}>
                    <span className={styles.PasswordUpdateTitle}>Editar senha</span>
                    <div className={styles.passwordUpdateItem}>
                        <label>CPF</label>
                        <input
                            type="password"
                            placeholder="Nova senha"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={styles.passwordInput}
                        />
                    </div>
                    <div className={styles.passwordUpdateItem}>
                        <label>CPF</label>
                        <input
                            type="password"
                            placeholder="Confirmar nova senha"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={styles.passwordInput}
                        />
                    </div>
                    <div className={styles.userUpdateRight}>
                        <button className={styles.passwordUpdateButton} onClick={handlePasswordUpdate}>
                            Atualizar senha
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );

    return (
        <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
    );
};

export default PerfilProfessorPage;
