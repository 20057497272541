import React from 'react'
import FrequenciaComponent from '../../../components/pagesComponents/FrequenciaComponents/FrequenciaComponent'
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const FrequenciaSecretariaPage= () => {
  const itemsPagesList = itemsPagesWithExclusions('Frequência', ['Atividades', 'Chat']);
  return (
    <>
      <FrequenciaComponent itemsPages={itemsPagesList} accessLevel={"high"} accessName={"administrador"}/>
    </>
  )
}

export default FrequenciaSecretariaPage;