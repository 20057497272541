import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import CalendarioComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioComponent';

const CalendarioCoordenacaoPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Calendário', ['agenda','Informes','Atividades','Chat','Fichas','Cardápio']);

  return (
    <div>
      <CalendarioComponent itemsPages={itemsPagesList} accessLevel="high"/>
    </div>
  );
};

export default CalendarioCoordenacaoPage;
