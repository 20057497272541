import React from 'react'
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';
import BoletimAlunoDetailComponent from '../../../../components/pagesComponents/BoletimComponents/BoletimAlunoDetail/BoletimAlunoDetailComponent';

const BoletimPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', []);
    return (
        <BoletimAlunoDetailComponent itemsPages={itemsPagesList} />
    );
}

export default BoletimPage