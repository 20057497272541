import React from 'react'
import BoletimAlunoDetailComponent from '../../../../components/pagesComponents/BoletimComponents/BoletimAlunoDetail/BoletimAlunoDetailComponent';
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';

const BoletimProfessorDetailPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', ['agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio']);
    return (
        <BoletimAlunoDetailComponent itemsPages={itemsPagesList} />
    );
}

export default BoletimProfessorDetailPage