import React from 'react';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';
import UsuariosComponent from '../../components/pagesComponents/UsersComponents/UsuariosComponent';

const UsuariosSecretaria = () => {
  const itemsPagesList =  itemsPagesWithExclusions('Painel', ['Atividades', 'Chat']);

  return (
    <UsuariosComponent itemsPages={itemsPagesList}/>
  );
};

export default UsuariosSecretaria;
