import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './BoletinsAlunosComponent.module.css';
import BasicPageStructure from '../../../BasicPageStructure';
import InterfaceStructure from '../../../InterfaceStructure';
import useTenantId from '../../../tenantId';
import { useSelector } from 'react-redux';
import { getAllUsers } from '../../../../actions/UserActions';
import { turno as turnoOptions } from '../../../../DataSeries';
import { Pagination } from '@mui/material';
import apiAxios from '../../../../api/apiAxios';

const ITEMS_PER_PAGE = 10;

const BoletinsAlunosComponent = ({ itemsPages, series, turno }) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSeries, setSelectedSeries] = useState('');
    const [selectedTurno, setSelectedTurno] = useState('');
    const [nivelEnsino, setNivelEnsino] = useState('');

    const tenantId = useTenantId();
    const navigate = useNavigate();
    const { id } = useParams();

    const userInfo = useSelector((state) => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

    const seriesPorNivel = {
        'Educação Infantil': ['Infantil I', 'Infantil II', 'Infantil III', 'Infantil IV', 'Infantil V'],
        'Fundamental I': ['1º ano', '2º ano', '3º ano', '4º ano', '5º ano'],
        'Fundamental II': ['6º ano', '7º ano', '8º ano', '9º ano'],
    };

    useEffect(() => {
        const fetchNivelEnsino = async () => {
            try {
                const response = await apiAxios.get(`/api/boletims/boletins/${id}`, {
                    params: { tenantId },
                });
                const boletim = response?.data?.data;
                setNivelEnsino(boletim?.nivelEnsino);

                const seriesPorNivelAtual = seriesPorNivel[boletim?.nivelEnsino] || [];

                if (Array.isArray(series) && series.length > 0) {
                    setSelectedSeries(series[0]);
                } else if (seriesPorNivelAtual.length > 0) {
                    setSelectedSeries(seriesPorNivelAtual[0]);
                } else {
                    console.warn("Nenhuma série disponível para o nível de ensino.");
                }
            } catch (error) {
                console.error('Erro ao buscar nível de ensino:', error);
                setError('Erro ao carregar o nível de ensino.');
            }
        };

        fetchNivelEnsino();
    }, [id, tenantId, series]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const users = await getAllUsers(tokenPayload.tenantId);
                const filteredUsers = users
                    .filter((user) => user.isAluno)
                    .filter((user) => user._id !== tokenPayload._id)
                    .reverse()
                    .map((user) => ({
                        id: user._id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email || 'N/A',
                        matricula: user.matricula || 'N/A',
                        CPF: user.CPF,
                        phoneNumber: user.phoneNumber,
                        alunoInfo: user.alunoInfo,
                    }));

                setUsers(filteredUsers);
                setFilteredUsers(filteredUsers);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao obter usuários:', error);
                setError('Erro ao carregar os dados.');
                setLoading(false);
            }
        };

        fetchData();
    }, [userInfo, tokenPayload.tenantId]);

    useEffect(() => {
        if (selectedSeries) {
            handleFilter(selectedSeries, selectedTurno);
        }
    }, [selectedSeries, users]);

    useEffect(() => {
        if (!selectedTurno) {
            if (turno && typeof turno === 'string') {
                setSelectedTurno(turno);
            } else if (turnoOptions.length > 0) {
                setSelectedTurno(turnoOptions[0]);
            }
        }
    }, [turno, turnoOptions, selectedTurno]);

    const handleViewBoletim = (alunoId) => {
        navigate(`/boletins/${id}/aluno/${alunoId}`);
    };

    const handleFilter = (newSeries = selectedSeries, newTurno = selectedTurno) => {
        let updatedUsers = users;

        if (newSeries) {
            updatedUsers = updatedUsers.filter((user) => user.alunoInfo?.serie === newSeries);
        }

        if (newTurno && typeof newTurno === "string") {
            updatedUsers = updatedUsers.filter((user) => {
                const turnoUsuario = user.alunoInfo?.turno || "";
                return turnoUsuario.toLowerCase() === newTurno.toLowerCase();
            });
        }

        setFilteredUsers(updatedUsers);
        setCurrentPage(1);
    };

    useEffect(() => {
        handleFilter(selectedSeries, selectedTurno);
    }, [selectedSeries, selectedTurno, users]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentUsers = filteredUsers.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const dashboardContent = (
        <div className={styles.userList}>
            <div className={styles.filters}>
                <div>
                    <label>Série:</label>
                    <div>
                        <select
                            value={selectedSeries}
                            onChange={(e) => setSelectedSeries(e.target.value)}
                        >
                            {Array.isArray(series) && series.length > 0
                                ? series.map((serie, index) => (
                                    <option key={index} value={serie}>
                                        {serie}
                                    </option>
                                ))
                                : (seriesPorNivel[nivelEnsino] || []).map((serie, index) => (
                                    <option key={index} value={serie}>
                                        {serie}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>

                <div>
                    <label>Turno:</label>
                    <select
                        value={selectedTurno}
                        onChange={(e) => setSelectedTurno(e.target.value)}
                    >
                        {(turno ? [turno] : turnoOptions).map((turnoOption, index) => (
                            <option key={index} value={turnoOption}>
                                {turnoOption}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {loading ? (
                <p>Carregando...</p>
            ) : error ? (
                <p>{error}</p>
            ) : currentUsers.length > 0 ? (
                <>
                    {currentUsers.map((user) => (
                        <div key={user.id} className={`${styles.userItem} ${styles.animateItem}`}>
                            <p>
                                <strong>Nome:</strong> {user.firstName} {user.lastName}
                            </p>
                            <p>
                                <strong>Matrícula:</strong> {user.matricula || 'Não informado'}
                            </p>
                            <p>
                                <strong>Série:</strong> {user.alunoInfo?.serie || 'Não informado'}
                            </p>
                            <p>
                                <strong>Turno:</strong> {user.alunoInfo?.turno || 'Não informado'}
                            </p>
                            <button
                                className={styles.viewButton}
                                onClick={() => handleViewBoletim(user.id)}
                            >
                                Ver Boletim
                            </button>
                        </div>
                    ))}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            count={Math.ceil(filteredUsers.length / ITEMS_PER_PAGE)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            className={styles.pagination}
                        />
                    </div>
                </>
            ) : (
                <p>Nenhum usuário encontrado.</p>
            )}
        </div>
    );

    const buttons = [];
    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={true}
                    returnLabel={'Voltar'}
                    returnPath={'/boletim'}
                    namePage="Alunos"
                    accessType="administrador"
                />
            }
        />
    );
};

export default BoletinsAlunosComponent;
