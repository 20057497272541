import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FaUserPlus } from "react-icons/fa";
import { deleteUserById, getAllUsers } from '../../../actions/UserActions';
import { useSelector } from 'react-redux';
import InterfaceStructure from '../../InterfaceStructure';
import BasicPageStructure from '../../BasicPageStructure';
import PopUpComponent from '../../popUp/PopUpComponent';
import AddUserComponent from './AddUserComponent';
import EditUserComponent from './EditUserComponent';
import styles from './UsuariosComponent.module.css';
import { IoIosClose } from "react-icons/io";
import MiniPopUpComponent from '../../popUp/MiniPopUpComponent';
import { useSnackbar } from 'notistack';
import EditUserPasswordComponent from './EditUserPassawordComponent';

const UsuariosComponent = ({ itemsPages }) => {
    const [data, setData] = useState([]);
    const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState(false);
    const [isEditUserPopupOpen, setIsEditUserPopupOpen] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const itemsPagesList = itemsPages;
    const { enqueueSnackbar } = useSnackbar();

    const [isEditPasswordPopupOpen, setIsEditPasswordPopupOpen] = useState(false);


    const accessLevel = tokenPayload.isAdmin ? 'high' :
        (tokenPayload.isCoordenaçao || tokenPayload.isSecretaria) ? 'low' : null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const users = await getAllUsers(tokenPayload.tenantId);
                const filteredUsers = users
                    .filter(user => user._id !== tokenPayload._id)
                    .filter(user => !user.isAdmin && !user.isAdminMaster)
                    .reverse()
                    .map(user => ({
                        id: user._id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email || 'N/A',
                        CPF: user.CPF,
                        phoneNumber: user.phoneNumber,
                        coordenacao: user.isCoordenaçao,
                        nutricionista: user.isNutricionista,
                        isProfessor: user.isProfessor,
                        isAluno: user.isAluno,
                        isResponsavel: user.isResponsavel,
                        isSecretaria: user.isSecretaria, // Adicionando o campo isSecretaria
                        alunoInfo: user.alunoInfo,
                        responsavelInfo: user.responsavelInfo,
                        professorInfo: user.professorInfo,
                    }));
                setData(filteredUsers);
            } catch (error) {
                console.error("Erro ao obter usuários:", error);
            }
        };

        fetchData();
    }, [userInfo, accessLevel, tokenPayload.tenantId]);

    const handleDeleteConfirmation = () => {
        if (selectedRows.length > 0) {
            setIsDeletePopupOpen(true);
        }
    };

    const confirmDeleteUser = async () => {
        try {
            await Promise.all(selectedRows.map(userId => deleteUserById(userId)));
            setData(prevData => prevData.filter(user => !selectedRows.includes(user.id)));
            setSelectedRows([]);
            setIsDeletePopupOpen(false);
            enqueueSnackbar('Usuário(s) deletado(s) com sucesso!', { variant: 'success' });
        } catch (error) {
            console.error("Erro ao excluir usuário:", error);
            enqueueSnackbar('Erro ao deletar usuário(s).', { variant: 'error' });
        }
    };

    const openEditUserModal = (userId) => {
        const userToEdit = data.find(user => user.id === userId);
        setSelectedUser(userToEdit);
        setIsEditUserPopupOpen(true);
    };

    const openEditPasswordModal = (userId) => {
        const userToEdit = data.find(user => user.id === userId);
        setSelectedUser(userToEdit);
        setIsEditPasswordPopupOpen(true);
    };


    const columns = [
        { field: 'firstName', headerName: 'Nome', width: 200 },
        { field: 'lastName', headerName: 'Sobrenome', width: 200 },
        { field: 'email', headerName: 'Email', width: 230 },
        { field: 'phoneNumber', headerName: 'Número', width: 180 },
        ...(accessLevel === 'low'
            ? [
                { field: 'isProfessor', headerName: 'Professor', width: 180 },
                { field: 'isAluno', headerName: 'Aluno', width: 180 },
                { field: 'isResponsavel', headerName: 'Responsável', width: 180 },
            ]
            : [
                { field: 'coordenacao', headerName: 'Coordenação', width: 180 },
                { field: 'nutricionista', headerName: 'Nutricionista', width: 180 },
                { field: 'isProfessor', headerName: 'Professor', width: 180 },
                { field: 'isResponsavel', headerName: 'Responsável', width: 180 },
                { field: 'isAluno', headerName: 'Aluno', width: 180 },
                { field: 'isSecretaria', headerName: 'Secretária', width: 180 },
            ]
        ),
    ];

    const buttons = [
        {
            label: 'Adicionar Usuário',
            icon: FaUserPlus,
            onClick: () => setIsAddUserPopupOpen(true),
            type: 'primary',
        },
    ];

    const dashboardContent = (
        <div className={styles.userList}>
            {selectedRows.length > 0 && (
                <div className={styles.actionButtons}>
                    <div className={styles.actionsLeft}>
                        <span>Você selecionou {selectedRows.length} |</span>
                        {selectedRows.length === 1 && (
                            <button
                                className={styles.userListEdit}
                                onClick={() => openEditUserModal(selectedRows[0])}
                            >
                                Editar
                            </button>
                        )}
                        {selectedRows.length === 1 && (
                            <button
                                className={styles.userPasswordEdit}
                                onClick={() => openEditPasswordModal(selectedRows[0])}
                            >
                                Editar Senha
                            </button>
                        )}
                        <button onClick={handleDeleteConfirmation} className={styles.userListDelete}>
                            Deletar
                        </button>
                    </div>
                    <button onClick={() => setSelectedRows([])} className={styles.closeButton}>
                        <IoIosClose size={24} />
                    </button>
                </div>
            )}
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={8}
                disableSelectionOnClick
                checkboxSelection
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            />
        </div>
    );

    return (
        <>
            <InterfaceStructure
                itemsPages={itemsPagesList}
                dashboardContent={
                    <BasicPageStructure
                        content={dashboardContent}
                        buttons={buttons}
                        returnPage={true}
                        namePage="Usuários"
                        returnPath="/"
                        returnLabel="Painel"
                    />
                }
            />
            <PopUpComponent
                title="Adicionar Usuário"
                isOpen={isAddUserPopupOpen}
                onClose={() => setIsAddUserPopupOpen(false)}
            >
                <AddUserComponent AcessLevel={accessLevel} />
            </PopUpComponent>
            <PopUpComponent
                title="Editar Usuário"
                isOpen={isEditUserPopupOpen}
                onClose={() => setIsEditUserPopupOpen(false)}
            >
                {selectedUser && <EditUserComponent AcessLevel={accessLevel} user={selectedUser} />}
            </PopUpComponent>
            {isDeletePopupOpen && (
                <MiniPopUpComponent
                    title="Confirmar Deleção"
                    message={`Você tem certeza que deseja deletar ${selectedRows.length} usuário(s)?`}
                    onConfirm={confirmDeleteUser}
                    onCancel={() => setIsDeletePopupOpen(false)}
                />
            )}
            <PopUpComponent
                title="Editar Senha do Usuário"
                isOpen={isEditPasswordPopupOpen}
                onClose={() => setIsEditPasswordPopupOpen(false)}
            >
                {selectedUser && <EditUserPasswordComponent userId={selectedUser.id} />}
            </PopUpComponent>


        </>
    );
};

export default UsuariosComponent;
