import React, { useEffect, useState } from 'react';
import styles from './BoletimComponent.module.css';
import apiAxios from '../../../../api/apiAxios'; // Para salvar as notas

const BoletimComponent = ({ boletimAluno, tenantId, boletimId, alunoId }) => {
    const [notas, setNotas] = useState(boletimAluno.notas || []);
    const [mediaGeral, setMediaGeral] = useState(null);

    useEffect(() => {
        const fetchBoletim = async () => {
            try {
                const response = await apiAxios.get(`/api/boletims/boletins/${boletimId}`);
                if (response.data && response.data.data.mediaGeral) {
                    setMediaGeral(response.data.data.mediaGeral);
                } else {
                    setMediaGeral(null);
                }
            } catch (error) {
                console.error('Erro ao buscar boletim:', error);
                setMediaGeral(null);
            }
        };

        fetchBoletim();
    }, [boletimId]);

    const handleNotaChange = async (materiaIndex, tipo, newNota) => {
        if (newNota > 10) {
            alert("A nota não pode ser maior que 10");
            return;
        }

        const updatedNotas = [...notas];
        const materia = updatedNotas[materiaIndex];
        const avaliacao = materia.avaliacoes.find((av) => av.tipo === tipo);

        if (avaliacao) {
            avaliacao.nota = parseFloat(newNota) || null;
        }

        materia.media = calculateMedia(materia.avaliacoes);
        materia.status = calculateStatus(materia.avaliacoes);

        setNotas(updatedNotas);

        try {
            await apiAxios.put('/api/boletims/boletins/aluno', {
                tenantId,
                boletimId,
                alunoId,
                notas: updatedNotas,
            });
            console.log('Notas salvas automaticamente');
        } catch (error) {
            console.error('Erro ao salvar notas:', error);
        }
    };

    const calculateMedia = (avaliacoes) => {
        const notasValidas = avaliacoes.filter((av) => av.nota !== null);
        if (notasValidas.length === 0) return null;
        const somaNotas = notasValidas.reduce((acc, av) => acc + (parseFloat(av.nota) || 0), 0);
        return (somaNotas / notasValidas.length).toFixed(1);
    };

    const calculateStatus = (avaliacoes) => {
        const notasValidas = avaliacoes.filter((av) => av.nota !== null);
        if (notasValidas.length < 3) return "Em Avaliação";

        const media = calculateMedia(avaliacoes);
        const notasBaixas = notasValidas.filter((av) => av.nota < 5).length;

        if (media >= mediaGeral && notasBaixas === 0) return "Aprovado";
        if (media >= 4 && media < mediaGeral && notasBaixas > 0) return "Recuperação";
        if (media < 4 || notasBaixas === 3) return "Reprovado";

        return "Pendente";
    };

    const getStatusClass = (status) => {
        switch (status) {
            case "Aprovado":
                return styles.statusAprovado;
            case "Recuperação":
                return styles.statusRecuperacao;
            case "Reprovado":
                return styles.statusReprovado;
            default:
                return "";
        }
    };

    return (
        <div className={styles.boletim}>
            <div className={styles.boletimTitulo}>Notas</div>
            <table className={styles.boletimTabela}>
                <thead>
                    <tr>
                        <th>Matéria</th>
                        {boletimAluno.notas[0]?.avaliacoes?.map((avaliacao, idx) => (
                            <th key={idx}>{avaliacao.tipo}</th>
                        ))}
                        <th>Média</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {notas.map((nota, index) => (
                        <tr key={index}>
                            <td>{nota.materia}</td>
                            {nota.avaliacoes.map((avaliacao, idx) => (
                                <td key={idx}>
                                    <input
                                        type="number"
                                        value={avaliacao.nota || ""}
                                        onChange={(e) =>
                                            handleNotaChange(index, avaliacao.tipo, e.target.value)
                                        }
                                    />
                                </td>
                            ))}
                            <td>{nota.media !== null ? nota.media : "Pendente"}</td>
                            <td className={getStatusClass(nota.status)}>
                                {nota.status !== null ? nota.status : "Pendente"}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BoletimComponent;
