import React, { useState, useEffect } from 'react';
import styles from './FichasComponent.module.css';
import { MdEdit, MdDeleteOutline, MdInsertLink } from 'react-icons/md';
import { GrFormView } from "react-icons/gr";
import { Link, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import { IoClose } from 'react-icons/io5';
import CreateFormComponent from './CreateFormComponent';
import EditFormCompornent from './EditFormComponent';
import { IoIosAddCircleOutline } from 'react-icons/io';
import apiAxios from '../../api/apiAxios';
import InterfaceStructure from '../InterfaceStructure';
import BasicPageStructure from '../BasicPageStructure';
import PopUpComponent from '../popUp/PopUpComponent';

const FichasComponent = ({ tenantId, PagesList }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [expandedForms, setExpandedForms] = useState({}); // Novo estado para rastrear quais formulários estão expandidos
    const navigate = useNavigate();
    const encryptPass = process.env.REACT_APP_FICHA_RESPONSE_PASS;

    const encryptId = (id) => {
        const encrypted = CryptoJS.AES.encrypt(id, encryptPass).toString();
        return encrypted.replace(/\//g, '_').replace(/\+/g, '-');
    };

    const fetchForms = async () => {
        try {
            const response = await apiAxios.get('/api/forms', { params: { tenantId } });
            setForms(response.data);
        } catch (error) {
            console.error('Erro ao buscar formulários:', error);
            enqueueSnackbar('Erro ao buscar formulários', { variant: 'error' });
        }
    };

    const deleteForm = async (id) => {
        if (window.confirm('Tem certeza de que deseja excluir este formulário?')) {
            try {
                await apiAxios.delete(`/api/forms/${id}`);
                enqueueSnackbar('Formulário excluído com sucesso!', { variant: 'success' });
                setForms(forms.filter(form => form._id !== id));
            } catch (error) {
                console.error('Erro ao excluir formulário:', error);
                enqueueSnackbar('Erro ao excluir o formulário.', { variant: 'error' });
            }
        }
    };

    const handleShowCreatePopup = () => setShowPopup(true);
    const handleHidePopup = () => {
        setShowPopup(false);
        setShowEditPopup(false);
    };

    const handleEditClick = (form) => {
        setSelectedForm(form);
        setShowEditPopup(true);
    };

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => enqueueSnackbar('Link copiado!', { variant: 'success' }))
            .catch(err => enqueueSnackbar('Erro ao copiar o link: ' + err, { variant: 'error' }));
    };

    useEffect(() => {
        if (tenantId) {
            fetchForms();
        }
    }, [tenantId]);

    // Função para alternar a exibição de todos os campos ou apenas os três primeiros
    const toggleShowAllFields = (formId) => {
        setExpandedForms((prevState) => ({
            ...prevState,
            [formId]: !prevState[formId], // Alterna entre mostrar tudo ou apenas os três primeiros
        }));
    };

    const dashboardContents = (
        <>
            <div className={styles.formList}>
                {forms.map((form) => {
                    const encryptedId = encryptId(form._id);
                    const fullLink = `https://escola.educacaoalternativa360.com.br/ficha/public/${encryptedId}`;
                    const isExpanded = expandedForms[form._id]; // Verifica se o formulário está expandido

                    return (
                        <div key={form._id} className={styles.formItem}>
                            <div className={styles.top}>
                                <div className={styles.left}>
                                    <div className={styles.icon}>
                                        <MdInsertLink
                                            onClick={() => copyToClipboard(fullLink)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <Link to={`/ficha/public/${encryptedId}`}>
                                        {fullLink}
                                    </Link>
                                </div>
                                <div className={styles.right}>
                                    <button className={styles.updateButton} onClick={() => handleEditClick(form)}><MdEdit /></button>
                                    <button onClick={() => deleteForm(form._id)}><MdDeleteOutline /></button>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                <h3>{form.title}</h3>
                                <p>{form.description}</p>
                                <hr />
                                <div className={styles.ficha}>
                                    {(isExpanded ? form.fields : form.fields.slice(0, 3)).map((f, index) => (
                                        <div key={index}>
                                            <p>{f.name}</p>
                                            <input placeholder={f.placeholder} disabled />
                                        </div>
                                    ))}
                                    {form.fields.length > 3 && (
                                        <button onClick={() => toggleShowAllFields(form._id)} className={styles.toggleButton}>
                                            {isExpanded ? 'Ver Menos' : 'Ver Mais'}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className={styles.senderButton}>
                                <button onClick={() => navigate(`/fichas/received/${form._id}`)}>
                                    <GrFormView style={{ fontSize: "20px" }} /> Visualizar fichas enviadas
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
            {showPopup && (
                <PopUpComponent
                    title="Adicionar Ficha"
                    isOpen={showPopup}
                    onClose={handleHidePopup}
                >
                    <CreateFormComponent />
                </PopUpComponent>
            )}

            {showEditPopup && selectedForm && (
                <PopUpComponent
                    title="Editar Ficha"
                    isOpen={showEditPopup}
                    onClose={handleHidePopup}
                >
                    <EditFormCompornent form={selectedForm} />
                </PopUpComponent>
            )}
        </>
    );

    const buttons = [
        {
            label: 'Adicionar Ficha',
            icon: IoIosAddCircleOutline,
            onClick: handleShowCreatePopup,
        },
    ];

    return (
        <>
            <InterfaceStructure itemsPages={PagesList} dashboardContent={
                <BasicPageStructure
                    content={dashboardContents}
                    buttons={buttons}
                    returnPage={false}
                    namePage="Formulário"
                    accessType='administrador'
                />
            } />
        </>
    );
};

export default FichasComponent;