import React from 'react';
import BoletinsAlunosComponent from '../../../../components/pagesComponents/BoletimComponents/BoletinsAlunos/BoletinsAlunosComponent';
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';

const BoletinsAlunosPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', []);

    return (
        <BoletinsAlunosComponent itemsPages={itemsPagesList} />
    );
};

export default BoletinsAlunosPage;
