import React from 'react';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';
import RequestsComponent from '../../components/pagesComponents/UsersComponents/requests/RequestsComponent';

const Requests = () => {
  const itemsPagesList = itemsPagesWithExclusions('', []);

  return (
    <RequestsComponent itemsPages={itemsPagesList}/>
  );
};

export default Requests;
